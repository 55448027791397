import React, {FC, ReactNode, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {RiUser3Fill, RiCameraFill, RiVideoChatLine, RiListCheck2} from 'react-icons/ri'
import {MdForum} from 'react-icons/md'
import {BsChatLeftTextFill} from 'react-icons/bs'
import {
  Dropdown,
  WarningDialogModal,
  breakpoints,
  colors,
  spacing,
  subheaderLight,
  textBold,
  textH1
} from '@webscale-oy/vaalikone-common-ui-base'
import {NavSectionWrapper, ProgressNavBarV2} from '@webscale-oy/vaalikone-common-ui-candidate'

type Props = {
  header: string
  description?: string
  continueCondition?: boolean
  navigationPath?: string
  navigationClickCount?: number
  children?: ReactNode
}

type SectionOption = {
  title: string
  path: string
  done?: boolean
  icon?: JSX.Element
}

export const AnswersWrapper: FC<Props> = ({children, header, description, continueCondition, navigationPath, navigationClickCount}) => {
  const {t} = useTranslation()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const topRef: React.RefObject<HTMLDivElement> | null = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined)
  const {candidateId} = useParams<{candidateId: string}>()

  const sections: SectionOption[] = [
    {
      title: t('sectionNav.bgQuestions'),
      path: `/${candidateId}/taustakysymykset`,
      done: false,
      icon: <RiUser3Fill />
    },
    {
      title: t('sectionNav.photo'),
      path: `/${candidateId}/kuva`,
      done: false,
      icon: <RiCameraFill />
    },
    {
      title: t('sectionNav.video'),
      path: `/${candidateId}/video`,
      done: false,
      icon: <RiVideoChatLine />
    },
    {
      title: t('sectionNav.questions'),
      path: `/${candidateId}/kysymykset`,
      done: false,
      icon: <BsChatLeftTextFill />
    },
    {
      title: t('sectionNav.mediapartnerQuestions'),
      path: `/${candidateId}/mediakumppanit`,
      done: false,
      icon: <MdForum />
    },
    {
      title: t('sectionNav.summary'),
      path: `/${candidateId}/yhteenveto`,
      done: false,
      icon: <RiListCheck2 />
    }
  ]

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo(0, topRef.current.offsetTop)
    }
  }, [])

  useEffect(() => {
    if (navigationPath) {
      onNavigateClick(navigationPath)
    } else if (navigateTo) {
      onNavigateClick(navigateTo)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationPath, navigateTo, navigationClickCount])

  const onConfirm = () => navigateTo && navigate(navigateTo)

  const onNavigateClick = (to: string) => {
    if (to === pathname) {
      return undefined
    }
    if (continueCondition === false) {
      setModalOpen(true)
      setNavigateTo(to)
    } else {
      navigate(to)
    }
  }

  const modalBodyTranslation = () => {
    if (pathname === `/${candidateId}/kuva`) {
      return t('confirmModal.bodyProfile')
    }
    if (pathname === `/${candidateId}/taustakysymykset`) {
      return t('confirmModal.bodyBgQuestions')
    }
    if (pathname === `/${candidateId}/kysymykset`) {
      return t('confirmModal.bodyQuestions')
    }
    if (pathname === `/${candidateId}/video`) {
      return t('confirmModal.bodyVideo')
    }
    if (pathname === `/${candidateId}/mediakumppanit`) {
      return t('confirmModal.bodyPartnerQuestions')
    }
    return ''
  }

  const modalHeaderTranslation = () => {
    if (pathname === `/${candidateId}/kuva`) {
      return t('confirmModal.headerProfile')
    }
    if (pathname === `/${candidateId}/taustakysymykset`) {
      return t('confirmModal.headerBgQuestions')
    }
    if (pathname === `/${candidateId}/kysymykset`) {
      return t('confirmModal.headerQuestions')
    }
    if (pathname === `/${candidateId}/video`) {
      return t('confirmModal.headerVideo')
    }
    if (pathname === `/${candidateId}/mediakumppanit`) {
      return t('confirmModal.headerPartnerQuestions')
    }
    return ''
  }

  const modalConfirmTranslation = () => {
    if (pathname === `/${candidateId}/kuva`) {
      return t('confirmModal.confirmProfile')
    }
    if (pathname === `/${candidateId}/taustakysymykset`) {
      return t('confirmModal.confirmBgQuestions')
    }
    if (pathname === `/${candidateId}/kysymykset`) {
      return t('confirmModal.confirmQuestions')
    }
    if (pathname === `/${candidateId}/video`) {
      return t('confirmModal.confirmVideo')
    }
    if (pathname === `/${candidateId}/mediakumppanit`) {
      return t('confirmModal.confirmPartnerQuestions')
    }
    return ''
  }

  const onModalCancel = () => {
    setModalOpen(false)
  }

  return (
    <ContentContainer ref={topRef}>
      <ProgressNavi
        sections={sections}
        currentPath={pathname}
        onSectionClick={to => onNavigateClick(to)}
        options={{hideNumbering: true}}
        menuButtonBgColorOpen={colors.blue_50}
        menuButtonTextColor={colors.blue_900}
        rightDivider={{
          color: colors.blue_600
        }}
        dropdownButtonBorder={`1px solid ${colors.blue_600}`}
      />
      {!!description && <InfoText>{t('pages.profilePage.mainDescription')}</InfoText>}
      <Header>{header}</Header>
      {children}
      {modalOpen && (
        <DialogCustomWidth
          headerText={modalHeaderTranslation()}
          cancelText={t('confirmModal.cancel')}
          confirmText={modalConfirmTranslation()}
          bodyText={modalBodyTranslation()}
          onConfirm={onConfirm}
          onClose={onModalCancel}
        />
      )}
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 760px;
  margin: 0 0 ${spacing.space_56}px 0;
  padding: ${spacing.space_16}px;

  ${ProgressNavBarV2} {
    width: calc(100vw - 48px);
    > div {
      > div {
        width: 100%;
      }
    }
    ${Dropdown} {
      button {
        ${textBold};
        > div {
          svg {
            width: 20px;
            height: 20px;
            margin-left: 12px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: 0;
    width: 100%;
  }
`

const Header = styled.h1`
  ${textH1};
  margin: ${spacing.space_48}px 0;
`

const InfoText = styled.p`
  ${subheaderLight}
  margin-bottom: ${spacing.space_32}px;
`

const DialogCustomWidth = styled(WarningDialogModal)`
  > div {
    width: 500px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    > div {
      width: 300px;
    }
  }
`

const ProgressNavi = styled(ProgressNavBarV2)`
  ${NavSectionWrapper} {
    width: 100%;
    border: 0;
    border-radius: 8px;
    > span {
      color: rgba(0, 0, 0, 0.6);
    }
    &.section-wrapper-active {
      background-color: #e2e9fd;
      > span {
        color: #3869d3;
      }
    }
  }
  width: calc(100vw - 48px);
  ${Dropdown} {
    button {
      ${textBold};
      > div {
        svg {
          width: 20px;
          height: 20px;
          margin-left: 12px;
        }
      }
    }
  }
`
