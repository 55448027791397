export const faqs = {
  fi: [
    {
      question: 'Millaisen kuvan voin ladata vaalikoneeseen?',
      answer: `Kuvan pienin mahdollinen koko on 100x133 pikseliä. Suositeltu koko on vähintään 360x480 pikseliä.
        Tuetut kuvaformaatit ovat .jpg, .jpeg ja .png`
    },
    {
      question: 'Voinko tallentaa vastaukseni ja tulla myöhemmin takaisin vastaamaan?',
      answer: `Voit, vastauksesi tallentuvat automaattisesti. Voit myöhemmin jatkaa vastaamista kirjautumalla ehdokaskoneeseen uudestaan osoitteessa https://ehdokaskone.yle.fi/tunnistaudu`
    },
    {
      question: 'Voinko muokata vastauksiani tai vaihtaa kuvaani myöhemmin?',
      answer:
        'Kyllä voit. Pääset takaisin vastaamaan vaalikoneeseen kirjautumislinkistä https://ehdokaskone.yle.fi/tunnistaudu. Vastaukset tulee antaa 6. toukokuuta kello 23.59 mennessä.'
    },
    {
      question: 'Kuinka kauan voin vastata vaalikoneen kysymyksiin?',
      answer: 'Voit käydä vastaamassa kysymyksiin niin pitkään kuin ehdokaskone on auki'
    },
    {
      question: 'Milloin eurovaaleissa voi äänestää?',
      answer: 'Ennakkoäänestys kotimaassa on 29.5. – 4.6.2024. Vaalipäivän äänestys on 9.6.2024.'
    },
    {
      question: 'Voiko muut käydä vastaamassa puolestani?',
      answer: `Ehdokaskoneeseen kirjaudutaan vahvalla tunnistautumisella (pankkitunnistautuminen tai mobiilivarmenne) ja tunnusten antaminen eteenpäin ei ole sallittua.`
    },
    {
      question: 'Miten taustakysymykset vaikuttavat ehdokaskoneeseen?',
      answer: `Vastauksesi taustakysymyksiin näkyvät esittelysivullasi, kun vaalikone on auki.
        Vastauksesi eivät vaikuta ehdokaskoneen suosituksiin sen vastaajille.`
    }
  ],
  en: [
    {
      question: 'What kind of answer profile picture can I upload?',
      answer: `The smallest possible size is 100x100 pixels. Recommended smallest size is 360x360 pixels.
        Supported image formats are .gif, .jpg, .jpeg ja .png.`
    },
    {
      question: 'Can I save my answers and come back later to continue answering?',
      answer: `Yes you can. Answer are saved automatically. You can later come back and continue answering.`
    },
    {
      question: 'For how long can I fill the election compass?',
      answer: `You can fill the election compass for as long as it is open for candidates 8.2.2023 which is until 1.3.2023 23.59'
        }`
    },
    {
      question: 'When does the voting section open and close for the voters?',
      answer: 'The voting section opens for the voters at xx.xx.xxxx hh:mm and closes at xx.xx.xxxx hh:mm.'
    },
    {
      question: 'Can I edit my answers or change my profile picture later?',
      answer: `Yes you can. You can come back and edit your information via the link you received in your invitation email.
        You can edit information for as long as the election compass is open for candidates.`
    },
    {
      question: 'Can others fill the election compass instead of me?',
      answer: `Only you can fill the election compass via the link you received to your email.
        Remember that the link you got is personal so do not share it.`
    },
    {
      question: 'How do background questions affect the election compass?',
      answer: `Your answers to the background questions are displayed on your profile page when the election compass is open for the public.
        Answers to background questions do not affect the recommendations of the election compass.`
    }
  ],
  sv: [
    {
      question: 'Hurudan profilbild kan jag ladda upp?',
      answer: `Minsta bilden du kan ladda upp är 100x133 pixel. Minsta rekommenderade storleken är 360x480 pixel.
        Stödda filtyper är .jpg, .jpeg och .png.`
    },
    {
      question: 'Kan jag spara mina svar och fortsätta att svara senare?',
      answer: `Det kan du, dina svar sparas automatiskt. Du kan fortsätta att svara senare genom att logga in i kandidatkompassen på nytt. Adressen är https://ehdokaskone.yle.fi/tunnistaudu`
    },
    {
      question: 'Kan jag ändra på mina svar eller byta bild senare?',
      answer: `Jo det kan du. Du kommer tillbaka in i valkompassen via inloggningslänken https://ehdokaskone.yle.fi/tunnistaudu. Dina slutgiltiga svar måste vara inne före den 6 maj klockan 23.59.`
    },
    {
      question: 'Hur lång tid har jag på mig att fylla i valkompassen?',
      answer:
        'Du kan svara på valkompassens frågor så länge som den är öppen för kandidater. Valkompassen stängs för kandidater den 6.5.2023 klockan 23:59.'
    },
    {
      question: 'När kan man rösta i EU-valet?',
      answer: 'Förhandsröstningen i Finland pågår mellan den 29 maj och 4 juni 2024. Den egentliga valdagen är den 9 juni 2024.'
    },
    {
      question: 'Kan någon annan svara för min räkning?',
      answer: `Man loggar in i kandidatkompassengenom att identifiera sig med bankkoder eller mobil-id. Det är inte tillåtet att ge sina bankkoder eller mobil-id till någon annan.`
    },
    {
      question: 'Hur inverkar bakgrundsfrågorna på valkompassen?',
      answer: `Dina svar på bakgrundsfrågorna visas på din profilsida när valkompassen är öppen för publiken.
        Svaren på bakgrundsfrågorna påverkar inte valkompassens rekommendationer.`
    }
  ]
}
