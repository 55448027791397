import {action, computed, makeAutoObservable, runInAction} from 'mobx'
import {NotificationStore} from './NotificationStore'
import {IQuestionCategory} from '../types/question-category'
import {IQuestion} from '../types/question'
import {authenticatedHeaders, get} from '../api/methods'

export class QuestionStore {
  categories: IQuestionCategory[] = []
  loading = false
  questions: IQuestion[] = []
  notificationStore: NotificationStore

  constructor(notificationsStore: NotificationStore) {
    this.notificationStore = notificationsStore
    makeAutoObservable(this)
  }

  @action
  async loadQuestions(candidateId: number): Promise<void> {
    this.loading = true
    const categories = await get<IQuestionCategory[]>(`${candidateId}/questions`, authenticatedHeaders())
    runInAction(() => {
      this.categories = categories
      this.loading = false
      this.questions = categories.flatMap(c => c.questions)
    })
  }

  @computed
  get questionCount(): number {
    return this.categories.reduce((sum, c) => c.questions.length + sum, 0)
  }

  getCategory(categoryId: number) {
    return this.categories.find(c => c.id === categoryId)
  }

  getQuestionForIndex(index: number): IQuestion | undefined {
    let counter = 0
    let question
    this.categories.forEach(c =>
      c.questions.forEach(q => {
        if (counter === index) {
          question = q
        }
        counter += 1
      })
    )
    return question
  }
}
