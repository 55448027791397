import * as React from 'react'
import styled from 'styled-components'
import {raise, theme} from '../theme'
import yleLogo from '../../images/yle_logo_musta.svg'

type Props = {
  children?: React.ReactNode
  className?: string
}

const WelcomeBoxComponent: React.FC<Props> = props => (
  <div className={props.className}>
    <YleLogo src={yleLogo} />
    {props.children}
  </div>
)

const YleLogo = styled.img`
  display: block;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  border: 6px solid #fff;
  border-radius: 10px;
  width: 120px;
`

export const WelcomeBox = styled(WelcomeBoxComponent)`
  position: relative;
  background: #fff;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 40px 40px 40px;
  max-width: 600px;
  width: calc(100% - 10px);
  border-radius: 4px;

  @media only screen and (max-width: ${theme.breaks.mobile}px) {
    padding: 60px 20px 60px 20px;
  }
  ${raise(3)}
`
