import * as React from 'react'
import {useEffect, useState, useLayoutEffect} from 'react'
import {useLocation, useParams, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {useTranslation} from 'react-i18next'
import {Stores} from '../stores'
import {useStores} from '../utils/hooks'
import {faqs} from '../utils/faqs'
import {theme} from '../theme'
import {observer} from 'mobx-react'
import {PageLoader, breakpoints} from '@webscale-oy/vaalikone-common-ui-base'
import {Footer, NextBackNav, TopNavBar} from '@webscale-oy/vaalikone-common-ui-candidate'
import { awsRum } from '../utils/awsRum.util'

interface IProps {
  computedMatch?: any
  children?: React.ReactNode
}

export const PrivateRoute: React.FC<IProps> = observer(({children}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const {candidateId} = useParams<{candidateId: string}>()
  const {
    candidateStore,
    questionStore,
    mediapartnerStore,
    backgroundAnswerStore,
    backgroundInfoStore,
    answerStore,
    municipalityStore,
    appStateStore
  } = useStores() as Stores
  const {t, i18n} = useTranslation()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const lang = i18n.language
  const topFaqs = faqs[lang || 'fi'] || []
  const candidate = candidateStore.candidate
  const [showAllFaqs, setShowAllFaqs] = useState(false)

  const hideNavDropdowns = pathname === '/' || pathname === `/${candidateId}/tervetuloa` || pathname === '/info'

  const logout = () => {
    candidateStore.logout()
    navigate('/tervetuloa')
  }

  useEffect(() => {
    awsRum?.addSessionAttributes({
      userId: candidateId!
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useLayoutEffect(() => {
    const loadData = async () => {
      setLoading(true)
      if (!candidateStore.isAuthenticated) {
        await candidateStore.init(Number(candidateId))
      }
      if (candidateStore.isAuthenticated) {
        await questionStore.loadQuestions(Number(candidateId))
        await mediapartnerStore.loadMediapartnerQuestions(Number(candidateId))
        await mediapartnerStore.loadMediapartnerQuestionsAnswers()
        await backgroundAnswerStore.loadAnswers()
        await backgroundInfoStore.loadQuestions(Number(candidateId))
        await answerStore.loadAnswers()
        await municipalityStore.loadMunicipalities(Number(candidateId))

        const requiredBgQuestions = backgroundInfoStore.questions.filter(bgq => bgq.important)
        const requiredBgQuestionsAnswered =
          requiredBgQuestions.every(q => backgroundAnswerStore.answers.find(a => q.id === a.info_id)) &&
          municipalityStore.findMunicipality(candidateStore.candidate?.municipality_id)

        if (requiredBgQuestionsAnswered) {
          appStateStore.setReadySections('taustakysymykset')
        }

        if (questionStore.questionCount === answerStore.answers.length) {
          appStateStore.setReadySections('kysymykset')
        }

        if (candidateStore.candidate?.image) {
          appStateStore.setReadySections('kuva')
        }
        if (mediapartnerStore.mpQuestionCount === mediapartnerStore.mediapartnerQuestionAnswers.length) {
          appStateStore.setReadySections('mediakumppanit')
        }
      }
      setLoading(false)
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId])

  useLayoutEffect(() => {
    if (candidateStore.status === 'unauthorized') {
      window.location.replace('/')
    }
    if (candidateStore.candidate?.status === 'COMPLETED' && window.location.search.includes('admin_token=')) {
      window.location.replace(`/${candidateStore.candidate?.id}/yhteenveto`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateStore.status])

  if (loading) {
    return (
      <LoadContainer>
        <PageLoader title={t('loader.loadingSite')!} />
      </LoadContainer>
    )
  }

  return (
    <MainWrapper>
      <TopNavBarCustom
        translations={{
          electionName: t('topNav.title'),
          profile: t('topNav.summaryPage'),
          logout: t('topNav.logout')
        }}
        onLanguageSelect={(l: string) => i18n.changeLanguage(l)}
        languages={[
          {key: 'fi', title: t('common.inFinnish')},
          {key: 'sv', title: t('common.inSwedish')}
        ]}
        candidateName={hideNavDropdowns ? undefined : `${candidate?.first_name} ${candidate?.last_name}`}
        selectedLanguage={lang}
        onProfileClick={() => navigate(`/${candidate?.id}/yhteenveto`)}
        onLogout={logout}
        overrideElements={
          hideNavDropdowns && {
            left: <HiddenDiv tabIndex={-1} />,
            right: <HiddenDiv tabIndex={-1} />
          }
        }
      />
      <MainContainer>
        {children}
        <StyledFooter
          onShowAllClick={() => setShowAllFaqs(true)}
          onHideRestClick={() => setShowAllFaqs(false)}
          faqOptions={showAllFaqs ? topFaqs : topFaqs.slice(0, 5)}
          translations={{
            leftHeader: t('footer.contactSectionSubHeader'),
            leftText: (
              <>
                {t('footer.contactSectionText')}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.BE_API = window.BE_API || {}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.BE_API.openChatWindow()
                  }}
                >
                  {t('footer.contactSectionChatText')}
                </a>
              </>
            ),
            rightHeader: t('footer.faqSectionSubHeader')!,
            showAll: t('footer.faqSectiosShowAll')!,
            hideRest: t('footer.faqSectionHideRest')!
          }}
        />
      </MainContainer>
    </MainWrapper>
  )
})

const MainWrapper = styled.div`
    min-height: 100vh;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;

    ${NextBackNav} {
        button:first-of-type:hover {
            color: white;
        }
    }
`

const MainContainer = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${theme.layout.headerHeight}px;
`

const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`
const TopNavBarCustom = styled(TopNavBar)`
  @media only screen and (min-width: ${breakpoints.mobile}) {
    & > h5 {
      display: flex;
      margin: auto 100px;
    }
  }
`
const StyledFooter = styled(Footer)`
  & a {
    color: rgb(56, 105, 211);
  }
`

const HiddenDiv = styled.div`
  visibility: hidden;
`
