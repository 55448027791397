import * as React from 'react'
import styled from 'styled-components'
import {theme} from '../theme'

type Props = {
  children?: React.ReactNode
  className?: string
}

export const InfoBoxClass: React.FC<Props> = ({className, children}) => {
  return <div className={className}>{children}</div>
}

export const InfoBox = styled(InfoBoxClass)`
  color: ${theme.color.textMain};
  padding-top: 10px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${theme.breaks.mobile}px) {
    padding-top: 5px;
    font-size: ${theme.fontSize.xs};
  }
`
