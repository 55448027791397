import React, {FC, useLayoutEffect} from 'react'
import {observer} from 'mobx-react'
import styled from 'styled-components'
import {spacing, radius, color} from '@yleisradio/yle-design-system'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import {PillButton} from '../elements/Button'
import {Stores} from '../stores'
import {useStores} from '../utils/hooks'
import {MainContainer, MainWrapper} from '../elements/Layout'
import {WelcomeBox} from '../elements/welcome.box'
import {TopNavBar} from '@webscale-oy/vaalikone-common-ui-candidate'
import {FormDropdown, FormInput, PageLoader} from '@webscale-oy/vaalikone-common-ui-base'

const emailRegex = /\S+@\S+\.\S+/

const CreateNewPageComponent: FC = observer(() => {
  const {t} = useTranslation()
  const {registrationStore, notificationStore} = useStores() as Stores
  const {
    authenticateCandidate,
    fetchElectionData,
    createNewCandidate,
    setNewCandidate,
    setError,
    error,
    loading,
    newCandidate,
    constituencies,
    parties
  } = registrationStore
  const location = useLocation()
  const navigate = useNavigate()

  useLayoutEffect(() => {
    const authenticateUser = async () => {
      const urlQueryParams = new URLSearchParams(location.search)
      const codeFromUrl = String(urlQueryParams.get('code'))

      try {
        const isNew = await authenticateCandidate(codeFromUrl)
        // Fix on race condition that prevents user from being logged in when user is returning.
        if (isNew) {
          await fetchElectionData()
          navigate('/luo-profiili', {replace: true})
        }
      } catch (err) {
        setError(true)
        // If login code is not valid, redirect to NoAuth page
        window.location.replace('/')
      }
    }
    if (location.search.includes('code=')) {
      authenticateUser()
    }
    if (location.search.includes('error=')) {
      notificationStore.createNotification(t('register.error'), 'error')
      window.location.replace('/tunnistaudu')
    }
    return setError(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useLayoutEffect(() => {
    setValue('constituency_id', constituencies?.[0]?.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constituencies])

  const setValue = (propertyName: string, val: string | number | undefined) => {
    if (propertyName === 'constituency_id') {
      setNewCandidate({
        ...newCandidate,
        constituency_id: Number(val),
        party_id: undefined
      })
    } else {
      setNewCandidate({
        ...newCandidate,
        [propertyName]: val
      })
    }
  }

  const everythingAnswered = () =>
    newCandidate.first_name &&
    newCandidate.first_name.trim().length > 0 &&
    newCandidate.last_name &&
    newCandidate.last_name.trim().length > 0 &&
    newCandidate.email &&
    newCandidate.email.trim().length > 0 &&
    emailRegex.test(newCandidate.email) &&
    newCandidate.constituency_id &&
    newCandidate.party_id

  return (
    <MainWrapper>
      {!loading ? (
        <>
          <TopNavBar
            translations={{
              electionName: t('topNav.title'),
              profile: t('topNav.profile'),
              logout: t('topNav.logout')
            }}
          />
          <MainContainer>
            <WelcomeBox>
              <h1>{t('register.pageHeader')}</h1>
              <p>{t('register.pageInfo')}</p>
              <FormInput
                label={t('register.firstname')}
                required
                type="text"
                value={newCandidate.first_name}
                onChange={val => setValue('first_name', val)}
                autocomplete="given-name"
              />
              <FormInput
                label={t('register.lastname')}
                required
                type="text"
                value={newCandidate.last_name}
                onChange={val => setValue('last_name', val)}
                autocomplete="family-name"
              />
              <FormInput
                label={t('register.email')}
                required
                type="email"
                value={newCandidate.email}
                onChange={val => setValue('email', val)}
                autocomplete="email"
              />
              {/*<FormDropdown
                label={t('register.constituency')}
                required
                selected={newCandidate.constituency_id}
                placeHolder={t('register.selectConstituency')}
                options={constituencies.map(c => ({key: c.id, title: c.name_fi}))}
                onSelect={val => {
                  setValue('constituency_id', val)
                }}
              />*/}
              <FormDropdown
                label={t('register.party')}
                required
                selected={newCandidate.party_id}
                placeHolder={t('register.selectParty')}
                disabled={!newCandidate.constituency_id}
                options={parties
                  .filter(p => p.constituency_id === null || p.constituency_id === newCandidate.constituency_id)
                  .map(p => ({key: p.id, title: p.name_fi}))}
                onSelect={val => setValue('party_id', val)}
              />
              <PillButton disabled={!everythingAnswered()} intent="yleprimary" onClick={() => createNewCandidate()}>
                {t('register.register')}
              </PillButton>
              {error && (
                <ErrorBg>
                  <ErrorModal>
                    <h1>{t('register.errorHeader')}</h1>
                    <p>{t('register.errorInfo')}</p>
                    <PillButton intent="yleprimary" onClick={() => setError(false)}>
                      Ok
                    </PillButton>
                  </ErrorModal>
                </ErrorBg>
              )}
            </WelcomeBox>
          </MainContainer>
        </>
      ) : (
        <ErrorBg>
          <PageLoader />
        </ErrorBg>
      )}
    </MainWrapper>
  )
})

export const CreateNewPage = CreateNewPageComponent

const ErrorBg = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 99;
  svg {
    margin-top: 20%;
  }
`

const ErrorModal = styled.div`
  max-width: 450px;
  position: absolute;
  background-color: white;
  border-radius: ${radius.MEDIUM};
  top: 20%;
  margin: 20px;
  h1 {
    color: ${color.RED_50};
    margin: ${spacing.SPACING_24};
  }
  p {
    margin: ${spacing.SPACING_24};
  }
  button {
    margin: ${spacing.SPACING_24} ${spacing.SPACING_24} ${spacing.SPACING_24} auto !important;
  }
`
