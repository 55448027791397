import {action, makeAutoObservable, observable} from 'mobx'
import * as React from 'react'

export type NotificationType = 'info' | 'error' | 'warning' | 'success'

export interface INotification {
  id: number
  message: string | React.ReactNode
  duration: number
  left: number
  type: NotificationType
  hideClose: boolean
}

export class NotificationStore {
  public notifications: INotification[] = []
  private idCounter = 0
  private timers: {id: number; timer: number}[] = []

  constructor() {
    makeAutoObservable(this)
  }

  @action
  public createNotification(message: string | JSX.Element, type: NotificationType = 'info', duration = 7, hideClose = false) {
    const id = this.idCounter + 1
    this.idCounter = id
    const notification: INotification = observable({id, message, duration, type, left: duration, hideClose})

    if (duration > 0) {
      const timer = window.setInterval(() => {
        this.decrementTimer(notification)
        if (notification.left < 0) {
          this.removeNotification(id)
        }
      }, 10)
      this.timers.push({id, timer})
    }

    this.notifications.push(notification)
    return id
  }

  @action
  public decrementTimer(notification: INotification) {
    notification.left -= 0.01
  }

  @action
  public removeNotification(id: number) {
    const index = this.notifications.findIndex(n => n.id === id)
    this.notifications.splice(index, 1)

    const timerIndex = this.timers.findIndex(t => t.id === id)
    const removed = this.timers.splice(timerIndex, 1)
    if (removed.length > 0) {
      clearInterval(removed[0].timer)
    }
  }
}
