import React from 'react'
import {VIDEO_HEIGHT, VIDEO_WIDTH} from '../../utils/constants'
import {chooseVideoAspect} from '../../utils/camera.util'

export type Props = {
  stream: MediaStream
  setVideoAspect: (aspect: string) => void
}

export const LiveStreamPreview: React.FC<Props> = ({stream, setVideoAspect}) => {
  const videoPreviewRef = React.useRef<any>()

  React.useEffect(() => {
    if (videoPreviewRef.current && stream) {
      videoPreviewRef.current.srcObject = stream
      videoPreviewRef.current.play()
      videoPreviewRef.current.addEventListener(
        'canplay',
        () => {
          videoPreviewRef.current.width = VIDEO_HEIGHT * (videoPreviewRef.current.videoWidth / videoPreviewRef.current.videoHeight)
          setVideoAspect(chooseVideoAspect(videoPreviewRef.current.videoWidth / videoPreviewRef.current.videoHeight))
        },
        {once: true}
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream])

  if (!stream) {
    return null
  }

  return <video ref={videoPreviewRef} width={VIDEO_WIDTH} height={VIDEO_HEIGHT} autoPlay muted playsInline />
}
