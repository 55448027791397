import { AwsRum, AwsRumConfig } from 'aws-rum-web'

let awsRumUtil: AwsRum

if (import.meta.env.VITE_ENVIRONMENT === 'production') {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: "eu-west-1:d621411d-293e-4334-8064-83150bdeb6e2",
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      telemetries: ["errors","http"],
      allowCookies: false,
      enableXRay: false,
      disableAutoPageView: true
    }

    const APPLICATION_ID: string = '7c9774e3-8ee7-4c98-9786-4ac0301191e0'
    const APPLICATION_VERSION: string = '1.0.0'
    const APPLICATION_REGION: string = 'eu-west-1'

    awsRumUtil = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    )
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}
if (import.meta.env.VITE_ENVIRONMENT === 'staging') {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: "eu-west-1:d0867a93-acaf-4f51-ae82-c28c4838fff7",
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: false,
      enableXRay: false,
      disableAutoPageView: true
    }

    const APPLICATION_ID: string = '0d1421c1-c9b3-4ead-8e37-569e9684cec6'
    const APPLICATION_VERSION: string = '1.0.0'
    const APPLICATION_REGION: string = 'eu-west-1'

    awsRumUtil = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    )
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

export const awsRum = awsRumUtil!