import React, {FC} from 'react'
import {FiX} from 'react-icons/fi'
import styled from 'styled-components'
import CSSTransition from 'react-transition-group/CSSTransition'
import FocusTrap from 'focus-trap-react'
import {theme, raise} from '../theme'
import {useKeyPress} from '../utils/hooks'

export interface IModal {
  isOpen: boolean
  onClose?: () => void
  className?: string
  width?: string
  height?: string
  onKeyChange?: () => void
  children: {
    header?: React.ReactNode | string
    body: React.ReactNode
    actions?: React.ReactNode | React.ReactNode[]
  }
}

const ModalComponent: FC<IModal> = ({isOpen, onClose, className, width, height, children, onKeyChange}) => {
  useKeyPress('Escape', () => {
    onKeyChange && onKeyChange()
  })

  const {header, body, actions} = children
  return (
    <CSSTransition in={isOpen} exit={!isOpen} mountOnEnter unmountOnExit classNames="appear" timeout={600}>
      <FocusTrap>
        <div className={className} onClick={() => onClose && onClose()}>
          <ModalContainer
            role="dialog"
            className="vaalikone-modal"
            width={width}
            height={height}
            onClick={e => e.stopPropagation()}
            aria-labelledby="title"
            aria-describedby="body"
          >
            <ModalHeader id="title" className="vaalikone-modal__header" tabIndex={0}>
              {header}
              {onClose && (
                <CloseButton onClick={onClose}>
                  <FiX size={24} />
                </CloseButton>
              )}
            </ModalHeader>
            <ModalBody id="body" className="vaalikone-modal__body" tabIndex={0}>
              {body}
            </ModalBody>
            {actions && <Actions>{actions}</Actions>}
          </ModalContainer>
        </div>
      </FocusTrap>
    </CSSTransition>
  )
}

const ModalContainer = styled.div<{width?: string; height?: string}>`
  min-width: 300px;
  min-height: 200px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${p => p.width || 'auto'};
  height: ${p => p.height || 'auto'};
  margin: auto;
  transition: all 0.3s ease;
  ${raise(2)}
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${theme.color.textInverse};
  font-weight: 300;
  background-color: ${theme.color.subtle};
  padding-left: 10px;
  border-radius: 4px 4px 0 0;
  height: 60px;
  font-size: 18px;
`
const ModalBody = styled.div`
  display: flex;
  flex-grow: 1;
  color: ${theme.color.textMain};
`
const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${theme.color.textInverse};
  cursor: pointer;
`
const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;

  > * {
    margin-left: 10px;
  }
`

export const Modal = styled(ModalComponent)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 100%);
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  margin-top: 0 !important;
  display: flex;

  &.appear {
    &-enter {
      opacity: 0;
      ${ModalContainer} {
        opacity: 0;
        animation: flipInX 0.7s ease;
        animation-delay: 0.2s;
      }
    }
    &-enter-active {
      transition: all 0.4s ease;
      opacity: 1;
    }

    &-exit {
      opacity: 1;
      ${ModalContainer} {
        opacity: 1;
        transform: scale(1);
      }
    }
    &-exit-active {
      transition: all 0.4s ease;
      opacity: 0;
      ${ModalContainer} {
        opacity: 0;
        transform: scale(0.2);
        transition: all 0.2s ease;
      }
    }
  }

  @keyframes flipInX {
    0% {
      transform: perspective(400px) rotateX(60deg);
      opacity: 0;
    }
    40% {
      transform: perspective(400px) rotateX(-10deg);
    }
    70% {
      transform: perspective(400px) rotateX(10deg);
    }
    100% {
      transform: perspective(400px) rotateX(0deg);
      opacity: 1;
    }
  }
`
export const ConfirmationModal = styled(Modal)`
  ${ModalHeader} {
    border-radius: 0 0 4px 4px;
    background-color: transparent;
    border-top: 6px solid;
  }

  ${ModalContainer} {
    border-radius: 0 0 4px 4px;
  }
`
