import {action, makeAutoObservable} from 'mobx'
import {IWizardSection} from '../types/wizardSection'

export class AppStateStore {
  visitedSections: IWizardSection[] = []
  readySections: IWizardSection[] = []
  eulaAccepted = false

  constructor() {
    makeAutoObservable(this)
  }

  @action
  setVisitedPageSections = (section: IWizardSection): void => {
    if (!this.visitedSections.includes(section)) {
      this.visitedSections = [...this.visitedSections, section]
    }
  }

  @action
  setReadySections = (section: IWizardSection): void => {
    if (!this.readySections.includes(section)) {
      this.readySections = [...this.readySections, section]
    }
  }

  @action
  setEulaAccepted = (accepted: boolean) => {
    this.eulaAccepted = accepted
  }
}
