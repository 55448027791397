import * as React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {theme} from '../theme'
import {Card} from '../elements/Card'

export const FAQPageClass: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Container>
      <Card>
        <h1>{t('ukk.title')}</h1>
        <Article dangerouslySetInnerHTML={{__html: t('ukk.html')}} />
      </Card>
    </Container>
  )
}

export const FAQPage = FAQPageClass

const Article = styled.article`
  color: ${theme.color.textDefault};

  h3 {
    margin-top: 32px;
    margin-bottom: 16px;
  }
  p {
    color: ${theme.color.textMain};
  }
`

const Container = styled.section`
  width: 100%;
  background-size: cover;
  background: #fff center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 70px 10px;

  ${Card} {
    max-width: 960px;
    padding: 20px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: ${theme.color.secondary};
    background-size: cover;
    z-index: -2;
    display: block;
    content: ' ';
    clip-path: polygon(0% 0%, 117% 0, 72% 74%, 0% 102%);
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    clip-path: polygon(72% 74%, 100% 27%, 100% 100%, 5% 100%);
    background: ${theme.color.primary.default};
    background-size: cover;
    display: block;
    content: ' ';
    z-index: -1;
    height: 100%;
    opacity: 0.9;
  }
`
