import React from 'react'

export const CheckIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00033 0.666668C4.40033 0.666668 0.666992 4.4 0.666992 9C0.666992 13.6 4.40033 17.3333 9.00033 17.3333C13.6003 17.3333 17.3337 13.6 17.3337 9C17.3337 4.4 13.6003 0.666668 9.00033 0.666668ZM7.33366 13.1667L3.16699 9L4.34199 7.825L7.33366 10.8083L13.6587 4.48333L14.8337 5.66667L7.33366 13.1667Z"
      fill="currentColor"
    />
  </svg>
)

export const RecordIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00033 17.3333C4.39783 17.3333 0.666992 13.6025 0.666992 9C0.666992 4.3975 4.39783 0.666668 9.00033 0.666668C13.6028 0.666668 17.3337 4.3975 17.3337 9C17.3337 13.6025 13.6028 17.3333 9.00033 17.3333ZM9.00033 11.5C9.66337 11.5 10.2993 11.2366 10.7681 10.7678C11.2369 10.2989 11.5003 9.66304 11.5003 9C11.5003 8.33696 11.2369 7.70108 10.7681 7.23223C10.2993 6.76339 9.66337 6.5 9.00033 6.5C8.33728 6.5 7.7014 6.76339 7.23256 7.23223C6.76372 7.70108 6.50033 8.33696 6.50033 9C6.50033 9.66304 6.76372 10.2989 7.23256 10.7678C7.7014 11.2366 8.33728 11.5 9.00033 11.5Z"
      fill="currentColor"
    />
  </svg>
)

export const ErrorTriangle = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.72183 0.500004L17.6602 14.25C17.7333 14.3767 17.7718 14.5204 17.7718 14.6667C17.7718 14.8129 17.7333 14.9566 17.6602 15.0833C17.587 15.21 17.4818 15.3152 17.3552 15.3884C17.2285 15.4615 17.0848 15.5 16.9385 15.5H1.06183C0.915549 15.5 0.771848 15.4615 0.645169 15.3884C0.518489 15.3152 0.413294 15.21 0.340156 15.0833C0.267018 14.9566 0.228515 14.8129 0.228516 14.6667C0.228517 14.5204 0.267022 14.3767 0.340161 14.25L8.27849 0.500004C8.35164 0.373331 8.45683 0.268142 8.58351 0.195009C8.71019 0.121875 8.85389 0.083374 9.00016 0.083374C9.14643 0.083374 9.29013 0.121875 9.41681 0.195009C9.54349 0.268142 9.64868 0.373331 9.72183 0.500004ZM8.16683 11.3333V13H9.83349V11.3333H8.16683ZM8.16683 5.5V9.66667H9.83349V5.5H8.16683Z"
      fill="#F43454"
    />
  </svg>
)
