import React, {FC, useEffect, useState} from 'react'
import styled from 'styled-components'
import axios from 'axios'
import {useTranslation} from 'react-i18next'
import {PillButton} from '../elements/Button'
import {theme} from '../theme'
import userCheck from '../../images/user-check.svg'
import downloadIcon from '../../images/download-icon.svg'
import {get} from '../api/methods'
import {useStores} from '../utils/hooks'
import questionsFile from '../../documents/Eurovaalien_2024_vaalikoneen_vaitteet.pdf'
import '../../documents/Tietosuojaseloste_Ehdokaskoneen_autentikointi.pdf'
import {MainContainer, MainWrapper} from '../elements/Layout'
import {WelcomeBox} from '../elements/welcome.box'
import {Stores} from '../stores'
import {TopNavBar} from '@webscale-oy/vaalikone-common-ui-candidate'

const RegisterPageClass: FC = () => {
  const [electionOpen, setElectionOpen] = useState(false)
  const {notificationStore, candidateStore} = useStores() as Stores
  const {t} = useTranslation()

  useEffect(() => {
    const getElectionOpen = async () => {
      const openForCandidates = await get<any>('election')
      setElectionOpen(openForCandidates.open_for_candidates)
    }
    getElectionOpen()
  })

  useEffect(() => {
    candidateStore.logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToAuthUrl = async () => {
    if (electionOpen) {
      const url = await axios.get(import.meta.env.VITE_SI_URL!)
      window.location.replace(url.data)
    } else {
      notificationStore.createNotification('Ehdokaskone on kiinni.', 'error')
    }
  }

  return (
    <MainWrapper>
      <TopNavBar
        translations={{
          electionName: t('topNav.title'),
          profile: t('topNav.profile'),
          logout: t('topNav.logout')
        }}
      />
      <MainContainer>
        <WelcomeBox>
          <h1 tabIndex={0}>{t('signup.welcome')}</h1>
          <WelcomeText tabIndex={0}>{t('signup.text1')}</WelcomeText>
          <WelcomeText tabIndex={0}>{t('signup.text2')}</WelcomeText>
          <WelcomeText tabIndex={0}>
            <span dangerouslySetInnerHTML={{__html: t('signup.text3')}} />
          </WelcomeText>
          <PillButton intent="yleprimary" onClick={goToAuthUrl}>
            <img src={userCheck} alt="" />
            {t('signup.authenticate')}
          </PillButton>
          <DownloadLink tabIndex={0} href={questionsFile} download>
            {t('signup.downloadQuestions')}
            <img src={downloadIcon} alt=""/>
          </DownloadLink>
        </WelcomeBox>
      </MainContainer>
    </MainWrapper>
  )
}

export const RegisterPage = RegisterPageClass

const WelcomeText = styled.p`
  color: ${theme.color.textMain};
  margin: 0 0 32px 0;
  a {
    text-decoration: underline;
  }
`

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  margin-top: 21px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  img {
    margin-left: 8px;
    width: 24px !important;
    height: 24px !important;
  }
`
