import React, {LegacyRef, useEffect, useRef, useState} from 'react'
import {VIDEO_HEIGHT, VIDEO_WIDTH} from '../../utils/constants'

export type Props = {
  audio?: Blob
  srcBlob: Blob | null
  setScreenshot: (screenshot: Blob) => void
}

export const Player: React.FC<Props> = ({srcBlob, audio, setScreenshot}) => {
  const [objectUrl, setObjectUrl] = useState<string | undefined>(undefined)
  const videoRef = useRef<HTMLVideoElement>()

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener(
        'seeked',
        () => {
          const canvas = document.createElement('canvas')
          canvas.width = videoRef.current!.width
          canvas.height = videoRef.current!.height

          const ctx = canvas.getContext('2d')
          ctx!.drawImage(videoRef.current!, 0, 0, canvas.width, canvas.height)

          canvas.toBlob(blob => {
            setScreenshot(blob!)
          }, 'image/jpeg')
        },
        {once: true}
      )
      videoRef.current.addEventListener(
        'canplay',
        () => {
          videoRef.current!.width = VIDEO_HEIGHT * (videoRef.current!.videoWidth / videoRef.current!.videoHeight)
          videoRef.current!.currentTime = 0
        },
        {once: true}
      )
    }
  }, [setScreenshot])

  useEffect(() => {
    if (srcBlob) {
      setObjectUrl(URL.createObjectURL(srcBlob))
    }
  }, [srcBlob])

  if (!srcBlob) {
    return null
  }

  if (audio) {
    return <audio src={objectUrl} controls />
  }

  return <video ref={videoRef as LegacyRef<HTMLVideoElement>} src={objectUrl} width={VIDEO_WIDTH} height={VIDEO_HEIGHT} controls />
}
