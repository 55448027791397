import {BaseInputProp} from '@webscale-oy/vaalikone-common-ui-base'
import type React from 'react'

export type InputBaseProps = {
  autoComplete?: string
  disabled?: boolean
  className?: string
  placeHolder?: string
  required?: boolean
  onKeyPress?: (event: React.KeyboardEvent) => void
  name?: string
  value?: string | number | null
  type?: 'email' | 'password' | 'text' | 'tel'
  onBlur?: (val: string) => void
  onChange: (val: string) => void
  min?: number
  max?: number
  step?: number | 'any'
  noScroll?: boolean
  dir?: 'rtl' | 'ltr'
} & BaseInputProp

export const InputAtom = ({
  type = 'text',
  autoComplete = 'off',
  className,
  value,
  placeHolder,
  disabled,
  name,
  required,
  onKeyPress,
  onChange,
  onBlur,
  inputId,
  min,
  max,
  noScroll,
  step,
  dir
}: Omit<InputBaseProps, 'type'> & {type?: 'email' | 'password' | 'text' | 'tel' | 'number' | 'range'}) => (
  <input
    id={inputId}
    autoComplete={autoComplete}
    type={type}
    className={className}
    value={value ?? undefined}
    placeholder={placeHolder}
    disabled={disabled}
    required={required}
    name={name}
    step={step}
    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event.target.value)
      }
    }}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        onChange(event.target.value)
      }
    }}
    onKeyPress={(event: React.KeyboardEvent) => onKeyPress && onKeyPress(event)}
    onWheel={e => {
      if (noScroll) {
        e.currentTarget.blur()
      }
    }}
    min={min}
    max={max}
    dir={dir}
  />
)
