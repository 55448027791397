import {BaseInputProp} from '@webscale-oy/vaalikone-common-ui-base'
import type React from 'react'

export type TextareaBaseProps = {
  disabled?: boolean
  value?: string
  placeHolder?: string
  required?: boolean
  onKeyPress?: (event: React.KeyboardEvent) => void
  onChange: (value: string) => void
  name?: string
  onBlur?: (val?: string) => void
  dir?: 'rtl' | 'ltr'
} & BaseInputProp

export const TextareaAtom: React.FC<TextareaBaseProps> = ({
  className,
  value,
  placeHolder,
  disabled,
  name,
  required,
  onKeyPress,
  onChange,
  inputId,
  onBlur,
  dir
}) => (
  <textarea
    id={inputId}
    className={className}
    value={value}
    placeholder={placeHolder}
    disabled={disabled}
    required={required}
    name={name}
    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => !disabled && !!onChange && onChange(event.target.value)}
    onKeyPress={(event: React.KeyboardEvent) => onKeyPress && onKeyPress(event)}
    onBlur={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        onBlur(event.target.value)
      }
    }}
    dir={dir}
  />
)
