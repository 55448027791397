import React, {FC} from 'react'
import styled from 'styled-components'
import {color, typography} from '@yleisradio/yle-design-system'
import alertCircleBlack from '../../images/alert_notification.svg'
import {theme} from '../theme'

interface IProps {
  text: string
}

export const WarningBox: FC<IProps> = ({text}) => (
  <BoxWrapper tabIndex={0}>
    <img src={alertCircleBlack} alt="varoitus-ikoni" />
    {text}
  </BoxWrapper>
)

const BoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  background-color: ${color.RED_5};
  border-radius: 8px;
  height: 48px;
  font-family: ${typography.OPEN_SANS_S_BOLD_FONT_FAMILY};
  font-size: ${typography.OPEN_SANS_S_BOLD_FONT_SIZE};
  && {
    margin-top: 30px;
    margin-bottom: 32px;
  }
  > img {
    margin-right: 11px;
  }

  @media only screen and (max-width: ${theme.breaks.tablet}px) {
    && {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: ${theme.breaks.mobile}px) {
    && {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
`
