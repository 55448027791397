import {action, makeAutoObservable, runInAction} from 'mobx'
import {authenticatedHeaders, get} from '../api/methods'
import {IMunicipality} from '../types/municipality'

export class MunicipalityStore {
  municipalities: IMunicipality[] = []
  loading = false

  constructor() {
    makeAutoObservable(this)
  }

  @action
  async loadMunicipalities(candidateId: number): Promise<void> {
    this.loading = true
    const municipalities = await get<IMunicipality[]>(`${candidateId}/municipalities`, authenticatedHeaders())
    runInAction(() => {
      this.municipalities = municipalities
      this.loading = false
    })
  }

  findMunicipality(id: number | undefined) {
    if (id) {
      return this.municipalities.find(m => m.id === id)
    }
    return undefined
  }
}
