import {action, makeAutoObservable} from 'mobx'
import * as React from 'react'

export type DialogType = 'info' | 'error' | 'warning' | 'success'

export interface IDialog {
  id: number
  header: string
  body: string | React.ReactNode
  type: DialogType
  onClose: (approve: boolean) => void
  hideCancelButton?: boolean
  hideContinueButton?: boolean
  continueTranslationKey?: string
}

export class DialogStore {
  dialogs: IDialog[] = []
  idCounter = 0

  constructor() {
    makeAutoObservable(this)
  }

  @action
  public openDialog(
    header: string,
    body: string | JSX.Element,
    type: DialogType = 'info',
    hideCancelButton?: boolean,
    hideContinueButton?: boolean,
    continueTranslationKey?: string
  ): Promise<boolean> {
    const id = this.idCounter + 1
    this.idCounter = id
    const promise = new Promise<boolean>(resolve => {
      const dialog: IDialog = {
        id,
        header,
        body,
        type,
        onClose: approve => {
          this.removeDialog(id)
          resolve(approve)
        },
        hideCancelButton,
        hideContinueButton,
        continueTranslationKey
      }
      this.dialogs.push(dialog)
    })
    return promise
  }

  @action
  public removeDialog(id: number) {
    const index = this.dialogs.findIndex(n => n.id === id)
    this.dialogs.splice(index, 1)
  }
}
