import React, {FC, useState, useEffect} from 'react'
import {observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {useNavigate, useParams} from 'react-router-dom'
import {Stores} from '../stores'
import {IBackgroundQuestion, IBackgroundAnswer, IBgType} from '../types/backgroundquestion'
import {useStores} from '../utils/hooks'
import {AnswersWrapper} from '../templates/AnswersWrapper.template'
import {deepClone} from '../utils/helper.util'
import {getBqAnswerFieldByType, getInitialBqValueByType, getRequiredQuestionIds, getValidAnswers} from '../utils/answer.util'
import {PageBottomProgress} from '../elements/PageBottomProgress'
import {WarningBox} from '../elements/WarningBox'
import {ResponsiveForm} from '../elements/ResponsiveForm'
import {IMunicipality} from '../types/municipality'
import {theme} from '../theme'
import {
  FormDropdown,
  FormNumberInput,
  FormRow,
  FormSelect,
  LinkButton,
  PaperWithStatus,
  Slider,
  YesNoQuestion,
  breakpoints,
  captionNormal,
  determineErrorStatus,
  electionTheme,
  spacing,
  subheaderLight,
  textH3,
  useForm
} from '@webscale-oy/vaalikone-common-ui-base'
import {NextBackNav} from '@webscale-oy/vaalikone-common-ui-candidate'
import {FormMultiLangTextarea} from '../components/FormElements/TextArea/MultiLangTextarea'
import {FormMultiLangInput} from '../components/FormElements/Input/MultiLangInput'
import {BackgroundLinkInput} from '@webscale-oy/vaalikone-common-ui-candidate/dist/candidate-ui/pages/BackgroundQuestionsPage/BackgroundLinkInput'
import {LinkBackgroundQuestion} from '@webscale-oy/vaalikone-api-models'

export interface BgQuestionForm {
  [key: number]: any
}

export type IProps = {
  readOnly?: boolean
}

export const BackgroundQuestionsPage: FC<IProps> = observer(() => {
  const [answers, setAnswers] = useState<IBackgroundAnswer[]>([])
  const [errors, setErrors] = useState<BgQuestionForm>({})
  const {backgroundInfoStore, backgroundAnswerStore, candidateStore, notificationStore, municipalityStore} = useStores() as Stores
  const {item, setNestedValue, hasErrors, setItem} = useForm<BgQuestionForm>({}, {})
  const {candidateId} = useParams<{candidateId: string}>()
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()
  const [updatebleAnswerId, setUpdatebleAnswerId] = useState<number | undefined>(undefined)
  const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined)
  const [navClickCount, setNavClickCount] = useState<number>(0)
  const [municipalities, setMunicipalities] = useState<IMunicipality[]>([])
  const readOnly = candidateStore.candidate?.status === 'COMPLETED'

  useEffect(() => {
    const candidateAnswers = backgroundAnswerStore!.answers
    setAnswers(candidateAnswers)
    if (candidateStore.candidate) {
      const constituencyMunicipalities = municipalityStore.municipalities
        .filter(m => m.constituency_id === candidateStore.candidate?.constituency_id)
        .sort((a, b) => (a[`name_${i18n.language}`] < b[`name_${i18n.language}`] ? -1 : 1))
      const otherMunicipalities = municipalityStore.municipalities
        .filter(m => m.constituency_id !== candidateStore.candidate?.constituency_id)
        .sort((a, b) => (a[`name_${i18n.language}`] < b[`name_${i18n.language}`] ? -1 : 1))
      setMunicipalities([...constituencyMunicipalities, ...otherMunicipalities])
    }
  }, [backgroundAnswerStore, candidateStore, i18n.language, municipalityStore.municipalities])

  useEffect(() => {
    const state = {} as any
    const errorsObj = {} as any
    ;(backgroundAnswerStore!.answers || []).forEach((answer: IBackgroundAnswer) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {info_id, candidate_id, ...rest} = answer
      state[answer.info_id] = deepClone(rest)
      const bq = backgroundInfoStore.questions.find(q => q.id === answer.info_id)
      errorsObj[answer.info_id] = bq?.important && !answer[getBqAnswerFieldByType(bq?.type, bq?.multi)] && t('textarea.requiredNotAnswered')
    })
    setItem(state)
    setErrors(errorsObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers])

  const updateAnswer = async (bq: IBackgroundQuestion, data: Partial<IBackgroundAnswer>, lang?: string) => {
    const answer = answers.find(a => a.info_id === bq.id)
    const savedAnswer = answer?.[getBqAnswerFieldByType(bq.type, bq.multi)]
    const answerChanged = savedAnswer !== data[getBqAnswerFieldByType(bq.type, bq.multi)]

    const multilangChanged = () => {
      if (bq.type === 'FREE_TEXT' || bq.type === 'SIMPLE_TEXT') {
        if (!data[getAnswerLangKey(lang)] && !answer?.[getAnswerLangKey(lang)]) {
          return false
        }
        return (
          data[getAnswerLangKey('fi')] !== answer?.[getAnswerLangKey('fi')] ||
          data[getAnswerLangKey('sv')] !== answer?.[getAnswerLangKey('sv')] ||
          data[getAnswerLangKey('en')] !== answer?.[getAnswerLangKey('en')] ||
          data[getAnswerLangKey('ru')] !== answer?.[getAnswerLangKey('ru')] ||
          data[getAnswerLangKey('sme')] !== answer?.[getAnswerLangKey('sme')] ||
          data[getAnswerLangKey('ara')] !== answer?.[getAnswerLangKey('ara')]
        )
      }
    }

    if (multilangChanged()) {
      await saveOrDelete(bq, data)
    } else if (bq.important && data[getBqAnswerFieldByType(bq.type, bq.multi)] === null) {
      setErrors({...errors, [bq.id]: t('textarea.requiredNotAnswered')})
      await saveOrDelete(bq, data)
    } else if (bq.type !== 'FREE_TEXT' && bq.type !== 'SIMPLE_TEXT' && answerChanged) {
      if (data[getBqAnswerFieldByType(bq.type, bq.multi)] === null) {
        await backgroundAnswerStore.deleteAnswer(bq.id)
        removeNullAnswerObject(bq)
      } else {
        await saveOrDelete(bq, data)
      }
    }
  }

  const saveOrDelete = async (bq: IBackgroundQuestion, data: Partial<IBackgroundAnswer>) => {
    const answer: IBackgroundAnswer = {
      ...data,
      info_id: bq.id
    }
    try {
      if (data[getBqAnswerFieldByType(bq.type, bq.multi)] === null) {
        await backgroundAnswerStore.deleteAnswer(bq.id)
        removeNullAnswerObject(bq)
      } else {
        await saveSingle(bq, answer)
      }
    } catch (error) {
      setErrors({...errors, [bq.id]: t('errors.failedToSaveField')})
      notificationStore!.createNotification(t('errors.unexpectedError'), 'error')
    }
  }

  const saveSingle = async (bgQuestion: IBackgroundQuestion, answer: IBackgroundAnswer) => {
    setUpdatebleAnswerId(answer.info_id)
    await backgroundAnswerStore!.postAnswer(answer)
  }

  const removeNullAnswerObject = (bq: IBackgroundQuestion) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      if (Number(key) !== bq.id) {
        acc[key] = item[key]
      }
      return acc
    }, {})
    setItem(updatedItem)
  }

  const getLangKey = (lang = i18n.language) => {
    switch (lang) {
      case 'sv':
        return 'name_sv'
      case 'en':
        return 'name_en'
      case 'ru':
        return 'name_ru'
      case 'fi':
        return 'name_fi'
      case 'sme':
        return 'name_sme'
      case 'ara':
        return 'name_ara'
      default:
        return 'name_fi'
    }
  }

  const getAnswerLangKey = (lang = i18n.language) => {
    switch (lang) {
      case 'sv':
        return 'answer_text_sv'
      case 'en':
        return 'answer_text_en'
      case 'ru':
        return 'answer_text_ru'
      case 'fi':
        return 'answer_text_fi'
      case 'sme':
        return 'answer_text_sme'
      case 'ara':
        return 'answer_text_ara'
      default:
        return 'answer_text_fi'
    }
  }

  const renderQuestion = (bq: IBackgroundQuestion) => {
    switch (bq.type) {
      case 'SIMPLE_TEXT':
        return (
          <FormMultiLangInput
            onChange={(lang, val) => {
              if (lang === 'fi') {
                setErrors({...errors, [bq.id]: bq.important && !val ? t('textarea.requiredNotAnswered') : false})
              }
              if (bq.max_length && val.length > bq.max_length) {
                setErrors({...errors, [bq.id]: t('textarea.overMax')})
              }
              setNestedValue(`${bq.id}.answer_text_${lang}`)(val)
            }}
            languages={{
              fi: t('textarea.fi'),
              sv: t('textarea.sv'),
              en: t('textarea.en'),
              ru: t('textarea.ru'),
              sme: t('textarea.sme'),
              ara: t('textarea.ara')
            }}
            activeLang={i18n.language}
            value={{
              fi: item[bq.id]?.[`answer_text_fi`],
              sv: item[bq.id]?.[`answer_text_sv`],
              en: item[bq.id]?.[`answer_text_en`],
              ru: item[bq.id]?.[`answer_text_ru`],
              sme: item[bq.id]?.[`answer_text_sme`],
              ara: item[bq.id]?.[`answer_text_ara`]
            }}
            errorMessage={errors[bq.id]}
            disabled={readOnly}
            onBlur={async (lang, val) => updateAnswer(bq, {...item[bq.id], [`answer_text_${lang}`]: val === '' ? null : val}, lang)}
            scrollShadow={{
              shadowColor: theme.color.dropShadow
            }}
          />
        )
      case 'SELECT':
        if (!bq.multi) {
          return (
            <FormDropdown
              selected={item[bq.id]?.numeric_answer}
              required={bq.important}
              options={
                bq.options?.map(opt => ({
                  key: opt.id!,
                  title: opt[getLangKey()]!
                })) || []
              }
              onSelect={async val => {
                setErrors({...errors, [bq.id]: bq.important && !val ? t('textarea.requiredNotAnswered') : false})
                setNestedValue(`${bq.id}.numeric_answer`)(val)
                await updateAnswer(bq, {...item[bq.id], numeric_answer: val})
              }}
              errorMessage={errors[bq.id]}
              disabled={readOnly}
              onClear={async () => {
                setNestedValue(`${bq.id}.numeric_answer`)(null)
                setErrors({
                  ...errors,
                  [bq.id]: bq.important ? t('textarea.requiredNotAnswered') : false
                })
                await updateAnswer(bq, {...item[bq.id], numeric_answer: null})
              }}
              clearButtonTranslation={t('multiSelect.clear')}
            />
          )
        } else {
          return (
            <FormSelect
              options={
                bq.options?.map(opt => ({
                  key: opt.id!,
                  title: opt[getLangKey()]!
                })) || []
              }
              selected={item[bq.id]?.answer_options || []}
              onSelect={async value => {
                const selected = item[bq.id]?.answer_options || []
                const index = selected.indexOf(value)

                if (index === -1) {
                  selected.push(value)
                } else {
                  selected.splice(index, 1)
                }
                setErrors({
                  ...errors,
                  [bq.id]: bq.important && selected.length === 0 ? t('textarea.requiredNotAnswered') : false
                })
                setNestedValue(`${bq.id}.answer_options`)(selected)
                await updateAnswer(bq, {...item[bq.id], answer_options: selected})
              }}
              renderOption={opt => (
                <>
                  <input type="checkbox" checked={(item[bq.id]?.answer_options || []).includes(opt.key)} readOnly />
                  {opt.title}
                </>
              )}
              includeSelected
              required={bq.important}
              keepOpenAfterSelection
              errorMessage={errors[bq.id]}
              disabled={readOnly}
              maxSelections={bq.max_answer_option_amount}
              clearSectionElement={
                <LinkButton
                  onClick={async () => {
                    setNestedValue(`${bq.id}.answer_options`)(null)
                    setErrors({
                      ...errors,
                      [bq.id]: bq.important ? t('textarea.requiredNotAnswered') : false
                    })
                    await updateAnswer(bq, {...item[bq.id], answer_options: null})
                  }}
                >
                  {t('multiSelect.clear')}
                </LinkButton>
              }
              classNames={{
                pillClass: 'bgselectpill'
              }}
            />
          )
        }
      case 'NUMERIC':
        const minValue = bq.min_numeric_value
        const maxValue = bq.max_numeric_value
        const validateNumber = (val: number) => {
          if (minValue && val < minValue) {
            return t('input.tooSmallValue')
          }
          if (maxValue && val > maxValue) {
            return t('input.tooBigValue')
          }
          return false
        }
        return (
          <FormNumberInput
            onChange={val => {
              const error = val && validateNumber(val)
              setErrors({
                ...errors,
                [bq.id]: error || (bq.important && !val && val !== 0 && t('textarea.requiredNotAnswered'))
              })
              if (!error) {
                setNestedValue(`${bq.id}.numeric_answer`)(val)
              }
            }}
            required={bq.important}
            value={item[bq.id]?.numeric_answer}
            errorMessage={errors[bq.id]}
            onBlur={async val => updateAnswer(bq, {...item[bq.id], numeric_answer: val === undefined ? null : val})}
            disabled={readOnly}
            min={0}
            max={maxValue}
          />
        )
      case 'FREE_TEXT':
        return (
          <FormMultiLangTextarea
            onChange={(lang, val) => {
              if (lang === 'fi') {
                setErrors({...errors, [bq.id]: bq.important && !val ? t('textarea.requiredNotAnswered') : false})
              }
              if (bq.max_length && val.length > bq.max_length) {
                setErrors({...errors, [bq.id]: t('textarea.overMax')})
              }
              setNestedValue(`${bq.id}.answer_text_${lang}`)(val)
            }}
            languages={{
              fi: t('textarea.fi'),
              sv: t('textarea.sv'),
              en: t('textarea.en'),
              ru: t('textarea.ru'),
              sme: t('textarea.sme'),
              ara: t('textarea.ara')
            }}
            activeLang={i18n.language}
            value={{
              fi: item[bq.id]?.[`answer_text_fi`],
              sv: item[bq.id]?.[`answer_text_sv`],
              en: item[bq.id]?.[`answer_text_en`],
              ru: item[bq.id]?.[`answer_text_ru`],
              sme: item[bq.id]?.[`answer_text_sme`],
              ara: item[bq.id]?.[`answer_text_ara`]
            }}
            errorMessage={errors[bq.id]}
            disabled={readOnly}
            onBlur={async (lang, val) => updateAnswer(bq, {...item[bq.id], [`answer_text_${lang}`]: val === '' ? null : val}, lang)}
            maxChars={bq.max_length}
            scrollShadow={{
              shadowColor: theme.color.dropShadow
            }}
          />
        )
      case 'MUNICIPALITY':
        return (
          <CustomFormDropdown
            selected={item[bq.id]?.numeric_answer}
            required={bq.important}
            allowSearch
            options={
              municipalities.map(opt => ({
                key: opt.id!,
                title: opt[getLangKey()]!
              })) || []
            }
            onSelect={async val => {
              setErrors({...errors, [bq.id]: bq.important && !val ? t('textarea.requiredNotAnswered') : false})
              setNestedValue(`${bq.id}.numeric_answer`)(val)
              await updateAnswer(bq, {...item[bq.id], numeric_answer: val})
            }}
            errorMessage={errors[bq.id]}
            disabled={readOnly}
            onClear={async () => {
              setNestedValue('numeric_answer')(null)
              setErrors({
                ...errors,
                [bq.id]: bq.important ? t('textarea.requiredNotAnswered') : false
              })
              await updateAnswer(bq, {...item[bq.id], numeric_answer: null})
            }}
            clearButtonTranslation={t('multiSelect.clear')}
          />
        )
      case 'LINK':
        return (
          <BackgroundLinkInput
            question={
              {
                ...bq,
                election_id: 1, // Not really needed in implementation
                text: {}
              } as LinkBackgroundQuestion
            }
            language={i18n.language}
            answer={item[bq.id]}
            languages={{
              fi: t('textarea.fi'),
              sv: t('textarea.sv'),
              en: t('textarea.en'),
              ru: t('textarea.ru'),
              sme: t('textarea.sme'),
              ara: t('textarea.ara')
            }}
            error={errors[bq.id]}
            onChange={async val => {
              setNestedValue(`${bq.id}.link_answer`)(val.link_answer)
              setErrors({
                ...errors,
                [bq.id]: bq.important ? t('textarea.requiredNotAnswered') : false
              })
            }}
            onError={(id, error) => setErrors({...errors, [id]: error})}
            onBlur={async (id, val) => {
              await updateAnswer(bq, {...item[id], link_answer: val.link_answer || undefined})
            }}
            onClearAnswer={async id => {
              await saveOrDelete(bq, {...item[id], link_answer: undefined})
            }}
            disabled={readOnly}
          />
        )

      case 'SLIDER':
        return (
          <Slider
            key={bq.id}
            min={bq.min_numeric_value}
            max={bq.max_numeric_value}
            onChange={async (val: number) => {
              setErrors({...errors, [bq.id]: bq.important && !val ? t('textarea.requiredNotAnswered') : false})
              setNestedValue(`${bq.id}.numeric_answer`)(val)
            }}
            onAfterChange={async (val: number) => {
              setErrors({...errors, [bq.id]: bq.important && !val ? t('textarea.requiredNotAnswered') : false})
              setNestedValue(`${bq.id}.numeric_answer`)(val)
              await updateAnswer(bq, {...item[bq.id], numeric_answer: val})
            }}
            value={item[bq.id]?.numeric_answer}
            label={
              bq.options?.find(opt => opt.target_value === item[bq.id]?.numeric_answer) &&
              bq.options?.find(opt => opt.target_value === item[bq.id]?.numeric_answer)![getLangKey()]
            }
            onClear={async () => {
              // middlevalue for rendering, null for db
              setNestedValue(`${bq.id}.numeric_answer`)(null)
              await saveOrDelete(bq, {...item[bq.id], numeric_answer: null})
            }}
            disabled={readOnly}
            additionalSectionElement={
              !item[bq.id]?.numeric_answer && <AdditionalSection>{t('backgroundQuestions.sliderInfo')}</AdditionalSection>
            }
          />
        )
      case 'YES_NO':
        return (
          <YesNowWrapper>
            <YesNoQuestion
              onChange={async value => {
                setNestedValue(`${bq.id}.numeric_answer`)(value)
                await updateAnswer(bq, {...item[bq.id], numeric_answer: value})
              }}
              answer={item[bq.id]?.numeric_answer}
              disabled={readOnly}
              appearance={'BUTTON'}
              valueForYes={5}
              valueForNo={1}
              clearSectionElement={
                <LinkButton
                  onClick={async () => {
                    setNestedValue(`${bq.id}.numeric_answer`)(null)
                    setErrors({
                      ...errors,
                      [bq.id]: bq.important ? t('textarea.requiredNotAnswered') : false
                    })
                    await updateAnswer(bq, {...item[bq.id], numeric_answer: null})
                  }}
                >
                  {t('multiSelect.clear')}
                </LinkButton>
              }
            />
          </YesNowWrapper>
        )
      default:
        return null
    }
  }

  const onNavclick = (to: string) => {
    setNavigateTo(to)
    setNavClickCount(navClickCount + 1)
  }

  const renderQuestions = (questions: IBackgroundQuestion[]) => {
    return (
      <QuestionRowContainer>
        {[...questions]
          .sort((a, b) => (a.position ?? 99999) - (b.position ?? 99999))
          .map(bq => (
            <PaperWithStatus
              paperState={determineErrorStatus(
                getInitialBqValueByType(bq.type, bq.multi),
                item[bq.id]?.[getBqAnswerFieldByType(bq.type, bq.multi)],
                errors[bq.id],
                backgroundAnswerStore.loading && updatebleAnswerId === bq.id
              )}
              key={bq.id}
            >
              <ComponentWrapper>
                <Column>
                  <QuestionHeader>
                    {bq[getLangKey()]} {bq.important && <RequiredStar />}
                  </QuestionHeader>
                </Column>
                <SelectWrapper type={bq.type}>{renderQuestion(bq)}</SelectWrapper>
              </ComponentWrapper>
            </PaperWithStatus>
          ))}
      </QuestionRowContainer>
    )
  }

  const answered = backgroundAnswerStore?.answers.filter(
    a =>
      (backgroundInfoStore.questions.find(q => q.id === a.info_id) && !!a.numeric_answer) ||
      !!a.answer_options ||
      !!a.answer_text_fi ||
      !!a.link_answer
  )

  const allRequiredAnswered = () => {
    const requiredQuestions = backgroundInfoStore.questions.filter(q => q.important)
    const requiredAnswers = backgroundAnswerStore?.answers.filter(a => requiredQuestions.find(q => q.id === a.info_id))
    return requiredQuestions.length === requiredAnswers.length && requiredQuestions.every(rq => !errors[rq.id])
  }

  return (
    <AnswersWrapper
      header={t('backgroundQuestions.mainHeader')}
      continueCondition={
        !readOnly ? getRequiredQuestionIds(backgroundInfoStore.questions).every(i => getValidAnswers(item, errors).includes(i)) : true
      }
      navigationPath={navigateTo}
      navigationClickCount={navClickCount}
    >
      <ResponsiveForm
        onSubmit={async () => {
          if (!hasErrors) {
            navigate('/kysymykset')
          }
        }}
      >
        <BackgroundQuestionExplanation>{t('backgroundQuestions.publicExplanation')}</BackgroundQuestionExplanation>
        {renderQuestions(backgroundInfoStore.questions.filter(q => q.public_field))}
        <Spacer />
        <BackgroundQuestionExplanation>{t('backgroundQuestions.yleMediaExplanation')}</BackgroundQuestionExplanation>
        {renderQuestions(backgroundInfoStore.questions.filter(q => !q.public_field))}
        {!allRequiredAnswered() && <WarningBox text={t('error.missingRequiredAnswers')} />}
        <PageBottomProgress
          infoTextBeginning={t('backgroundQuestions.infoBeginning')}
          infoTextEnd={t('backgroundQuestions.infoEnding')}
          amountOfAnswers={answered.length}
          amountOfQuestions={backgroundInfoStore.questions.length}
        />
        <NextBackNav
          backTo={() => onNavclick(`/${candidateId}/info`)}
          nextTo={() => onNavclick(`/${candidateId}/kuva`)}
          continueDisabled={Object.values(errors).some(Boolean)}
          translations={{
            next: t('nextBackNav.continue'),
            back: t('nextBackNav.back'),
            finish: t('nextBackNav.finish')
          }}
        />
      </ResponsiveForm>
    </AnswersWrapper>
  )
})

const ComponentWrapper = styled(FormRow)`
  display: flex;
  justify-content: space-between;
  width: 760px;
  flex-direction: column;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`
const Column = styled.div`
  display: flex;
  justify-content: center;
`
const SelectWrapper = styled(Column)<{type?: IBgType}>`
  > div {
    width: ${({type}) => type !== 'YES_NO' && '100%'};
  }

  ${FormSelect}, ${FormDropdown} {
    ${LinkButton} {
      position: absolute;
      right: 0;
    }
    .bgselectpill {
      max-width: calc(100% - 5px);
      span {
        max-width: 100%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`

const QuestionHeader = styled.h3`
  ${textH3};
  text-align: left;
`

const RequiredStar = styled.span`
  color: ${({theme}) => theme.errorForeground};
  &::before {
    content: '*';
  }
`

const BackgroundQuestionExplanation = styled.p`
  ${subheaderLight};
  width: 100%;
  margin-bottom: 30px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`
const Spacer = styled.div`
  height: 50px;
`
const CustomFormDropdown = styled(FormDropdown)`
  input,
  input:hover,
  input:focus {
    border: 0;
  }
`

const AdditionalSection = styled.p`
  ${captionNormal};
`

const QuestionRowContainer = styled.div`
  ${PaperWithStatus} {
    margin-bottom: ${spacing.space_24}px;
  }
`

export const YesNowWrapper = styled.div`
  button {
    border: 1px solid ${electionTheme.primary};
    min-width: 100px;
    min-height: 40px;
  }
  ${LinkButton} {
    border: none;
    font-weight: 400;
  }
`
