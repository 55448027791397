import {log} from './log.util'
import 'webrtc-adapter'

const SUPPORTED_VIDEO_HEIGHTS = [1920, 1600, 1366, 1280, 1024, 960, 854, 640, 426, 320, 256]

export type VideoAspect = '9:16' | '3:4' | '1:1' | '4:3' | '16:9'

const tryResolution = async (videoTrack: MediaStreamTrack, width: number, height: number) => {
  try {
    log(`Trying resolution ${width}x${height}...`)
    await videoTrack.applyConstraints({
      width: {exact: width},
      height: {exact: height}
    })
    log(`Using resolution: ${width}x${height}`)
    return true
  } catch (e) {
    console.error(`Resolution ${width}x${height} failed.`, e)
    return false
  }
}

const tryConstraint = async (constraint: any) => {
  const media = await window.navigator.mediaDevices.getUserMedia({video: true, audio: true})
  try {
    log(`Trying constraint:`, constraint)
    const videoTrack = media.getVideoTracks()[0]
    await videoTrack.applyConstraints(constraint)
    log(`Using constraint:`, constraint)
    return true
  } catch (e) {
    console.error(`Constraint failed.`, e)
    return false
  } finally {
    media.getTracks().forEach(t => t.stop())
  }
}

export const getMediaStreamConstraints = async (): Promise<MediaStreamConstraints> => {
  if (!navigator.mediaDevices) {
    throw new Error('Video devices not found.')
  }
  const defaults = {
    audio: {
      echoCancellation: true,
      channelCount: 2
    },
    video: {
      aspectRatio: 1,
      width: {ideal: 1920},
      height: {ideal: 1920}
    }
  }
  const supportedConstraints: any = navigator.mediaDevices.getSupportedConstraints()
  const constraints: any = {
    audio: {},
    video: {}
  }
  if (supportedConstraints.echoCancellation && await tryConstraint({ audio: { echoCancellation: defaults.audio.echoCancellation }})) {
    constraints.audio.echoCancellation = defaults.audio.echoCancellation
  }
  if (supportedConstraints.channelCount && await tryConstraint({ audio: { channelCount: defaults.audio.channelCount }})) {
    constraints.audio.channelCount = defaults.audio.channelCount
  }
  if (supportedConstraints.aspectRatio && await tryConstraint({ video: { aspectRatio: defaults.video.aspectRatio }})) {
    constraints.video.aspectRatio = defaults.video.aspectRatio
  }
  if (supportedConstraints.height && supportedConstraints.width) {
    const media = await window.navigator.mediaDevices.getUserMedia({video: constraints.video || true, audio: constraints.audio || true})
    const videoTrack = media.getVideoTracks()[0]
    for (const height of SUPPORTED_VIDEO_HEIGHTS) {
      if (await tryResolution(videoTrack, height, height)) {
        constraints.video.height = {exact: height}
        constraints.video.width = {exact: height}
        break
      }
    }
    media.getTracks().forEach(t => t.stop())
  }
  // Device choosing must be after initialization.
  if (supportedConstraints.deviceId) {
    const devices = await window.navigator.mediaDevices.enumerateDevices()
    log('All devices:', devices)
    const videoDevices = devices.filter(d => d.kind === 'videoinput')
    if (!videoDevices.length) {
      throw new Error('No usable video devices.')
    }
    const audioDevices = devices.filter(d => d.kind === 'audioinput')
    if (!audioDevices.length) {
      throw new Error('No usable audio devices.')
    }
    log('Using devices:', audioDevices[0], videoDevices[0])
    constraints.audio.deviceId = {exact: audioDevices[0].deviceId}
    constraints.video.deviceId = {exact: videoDevices[0].deviceId}
  }
  log('Using constraints:', constraints)
  return constraints
}

export function getAllSupportedMimeTypes(...mediaTypes: string[]) {
  if (!mediaTypes.length) mediaTypes.push(...['video', 'audio'])
  const FILE_EXTENSIONS = ['webm', 'ogg', 'mp4', 'mpeg', 'x-matroska', 'quicktime', '3gp']
  const CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus']

  const mimetypes = [...new Set(FILE_EXTENSIONS.flatMap(ext => mediaTypes.flatMap(mediaType => [`${mediaType}/${ext}`])))].filter(
    variation => MediaRecorder.isTypeSupported(variation)
  )

  const supported = [
    ...CODECS.flatMap(codec => mimetypes.flatMap(mimetype => [`${mimetype};codecs:${codec}`, `${mimetype};codecs=${codec}`]))
  ].filter(variation => MediaRecorder.isTypeSupported(variation))

  const all = [...new Set([...mimetypes, ...supported])]

  log('Supported mimetypes:', all)

  return all
}

/**
 * Video aspects:
 * 9:16 = 0.5625
 * 3:4 = 0.75
 * 1:1 = 1
 * 4:3 = 1.333333
 * 16:9 = 1.777777
 * @param aspect
 */
export const chooseVideoAspect = (aspect: number): VideoAspect => {
  switch (true) {
    case aspect < 0.65:
      return '9:16'
    case aspect < 0.85:
      return '3:4'
    case aspect < 1.1:
      return '1:1'
    case aspect < 1.5:
      return '4:3'
    default:
      return '16:9'
  }
}

export const getVideoWidthAspect = (aspect: VideoAspect): number => {
  switch (aspect) {
    case '9:16':
      return 9 / 16
    case '3:4':
      return 3 / 4
    case '1:1':
      return 1
    case '4:3':
      return 4 / 3
    case '16:9':
      return 16 / 9
  }
}
