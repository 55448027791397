import React, {FC, useState, useEffect} from 'react'
import {FiLock} from 'react-icons/fi'
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import {theme} from '../theme'
import {PillButton} from '../elements/Button'
import {Stores} from '../stores'
import {Input} from '../elements/Input'

import {WelcomeBox} from '../elements/welcome.box'
import {InfoBox} from '../elements/Info'
import {ErrorBox} from '../elements/ErrorBox'
import {useStores} from '../utils/hooks'
import {MainContainer, MainWrapper} from '../elements/Layout'

const VerifyPageClass: FC = observer(props => {
  const [smsToken, setSmsToken] = useState('')
  const [smsLoginToken, setSmsLoginToken] = useState('')
  const {t} = useTranslation()
  const {candidateStore} = useStores() as Stores
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const urlQueryParams = new URLSearchParams(location.search)
    const tokenFromUrl = String(urlQueryParams.get('sms_login_token'))
    setSmsLoginToken(tokenFromUrl)
    candidateStore.smsToken(tokenFromUrl)
  }, [candidateStore, location.search])

  const login = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await candidateStore!.smsLogin(smsToken, smsLoginToken)
      if (candidateStore!.isAuthenticated) {
        if (candidateStore.candidate?.status === 'COMPLETED') {
          navigate(`/${candidateStore.candidateId}/yhteenveto`)
        } else {
          navigate(`/${candidateStore.candidateId}/tervetuloa`)
        }
      }
    } catch (error) {
      candidateStore.notificationStore.createNotification('Tunnistautuminen epäonnistui.', 'error')
    }
  }

  return (
    <MainWrapper>
      <MainContainer>
        <SMSLoginBox>
          <form onSubmit={e => login(e)}>
            <h1>{t('sms.authenticationHeader')}</h1>
            <p>{t('sms.authenticationInfo')}</p>
            <SMSTokenInput type="text" icon={<FiLock />} placeHolder="******" onChange={val => setSmsToken(val)} value={smsToken} />
            <PillButton intent="yleprimary">{t('sms.authenticate')}</PillButton>
          </form>
          <InfoBox>
            <span dangerouslySetInnerHTML={{__html: t('sms.help')}} />
          </InfoBox>
          <InfoBox>
            <span dangerouslySetInnerHTML={{__html: t('common.help')}} />
          </InfoBox>
          {candidateStore!.status === 'link_error' && (
            <ErrorBox>
              <h4>{t('sms.linkErrorTitle')}</h4>
              <p>{t('sms.linkErrorText')}</p>
            </ErrorBox>
          )}
        </SMSLoginBox>
      </MainContainer>
    </MainWrapper>
  )
})
export const SMSLoginPage = VerifyPageClass
const SMSTokenInput = styled(Input)`
  max-width: 280px;
  margin: 10px auto 20px auto;
  input {
    letter-spacing: 20px;
    font-size: ${theme.fontSize.large};
    padding-left: 50px !important;
  }
`
const SMSLoginBox = styled(WelcomeBox)`
  ${PillButton} {
    width: 100%;
  }
  ${InfoBox} {
    margin-top: 10px;
  }
  p {
    color: ${theme.color.textMain};
  }
  form {
    text-align: center;
  }
`
