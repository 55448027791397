import {ComponentSize, highlight, spacing, subheaderLight} from '@webscale-oy/vaalikone-common-ui-base'
import type React from 'react'
import styled from 'styled-components'
import {InputAtom, InputBaseProps} from './InputBase'

type Props = {
  className?: string
  hint?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  size?: ComponentSize
  htmlFor?: string
  onClick?: () => void
}
export type InputProps = Props & Omit<InputBaseProps, 'value'> & {value?: string | null}
export type NumberInputProps = Props &
  Omit<InputBaseProps, 'value' | 'type' | 'onChange' | 'onBlur'> & {
    value?: number | null
    onBlur?: (val?: number) => void
    onChange: (val?: number) => void
    min?: number
    max?: number
    step?: number | 'any'
    noScroll?: boolean
  }

const InputBase = ({className, iconRight, hint, iconLeft, size, htmlFor, onClick, inputId, ...props}: InputProps) => {
  function padding() {
    const space = inputSize(size)
    if (!!iconLeft && !!iconRight) return `${space} ${spacing.space_40}px ${space} ${spacing.space_40}px;`
    if (iconRight) return `${space} ${spacing.space_40}px ${space} ${space};`
    if (iconLeft) return `${space} ${space} ${space} ${spacing.space_40}px`
    return space
  }

  return (
    <InputRow onClick={onClick} disabled={props.disabled} className={className}>
      {iconLeft && <IconLeft>{iconLeft}</IconLeft>}
      <InputText name={htmlFor || 'general_input'} padding={padding()} {...props} />
      {iconRight && <IconRight>{iconRight}</IconRight>}
    </InputRow>
  )
}

const NumberInputBase = ({
  className,
  iconRight,
  hint,
  iconLeft,
  size,
  htmlFor,
  onClick,
  value,
  onChange,
  onBlur,
  ...props
}: NumberInputProps) => {
  function padding() {
    const space = inputSize(size)
    if (!!iconLeft && !!iconRight) return `${space} ${spacing.space_40}px ${space} ${spacing.space_40}px;`
    if (iconRight) return `${space} ${spacing.space_40}px ${space} ${space};`
    if (iconLeft) return `${space} ${space} ${space} ${spacing.space_40}px`
    return space
  }

  return (
    <InputRow onClick={onClick} disabled={props.disabled} className={className}>
      {iconLeft && <IconLeft>{iconLeft}</IconLeft>}
      <InputText
        value={value}
        type="number"
        onChange={val => onChange(val || val === '0' ? Number(val) : undefined)}
        onBlur={val => onBlur && onBlur(val || val === '0' ? Number(val) : undefined)}
        padding={padding()}
        {...props}
      />
      {iconRight && <IconRight>{iconRight}</IconRight>}
    </InputRow>
  )
}

function inputSize(size?: ComponentSize) {
  switch (size) {
    case 'lg':
      return `${spacing.space_16}px`
    case 'xl':
      return `${spacing.space_24}px`
    default:
      return `${spacing.space_12}px`
  }
}

const InputText = styled(InputAtom)<{padding: string}>`
  ${subheaderLight};
  display: flex;
  border-radius: ${({theme}) => theme.radius};
  box-shadow: none;
  border: ${({theme}) => `1px solid ${theme.borderMain}`};
  transition: all 0.2s ease-in;
  background-color: ${({theme}) => theme.background};
  color: ${({theme}) => theme.text};
  width: 100%;
  padding: ${({padding}) => padding};

  &:focus {
    outline: 0;
    border: ${({theme}) => `1px solid ${theme.primary}`};
    ${highlight('primary')};
  }

  &:disabled {
    background-color: ${({theme}) => theme.backgroundDisabled};
    color: ${({theme}) => theme.textSecondary};
    cursor: not-allowed;
  }

  &:hover {
    border: ${({theme}) => `1px solid ${theme.primary}`};
  }
`

const IconElement = styled.span`
  height: 100%;
  width: 20px;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    fill: ${({theme}) => theme.background};
  }
`

const IconLeft = styled(IconElement)`
  left: ${spacing.space_12}px;
`

const IconRight = styled(IconElement)`
  right: ${spacing.space_12}px;
`

const InputRow = styled.div<{disabled?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  > div {
    width: 100%;
  }
`

export const Input = styled(InputBase)`
  position: relative;
`
export const NumberInput = styled(NumberInputBase)`
  position: relative;
`
