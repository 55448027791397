import {css} from 'styled-components'
import {color as colour} from '@yleisradio/yle-design-system'

export const theme = {
  color: {
    textMainLightest: 'rgba(0, 0, 0, .29)',
    textMainLighter: colour.GRAY_60,
    textMain: colour.GRAY_95,
    textDefault: '#696D85',
    textDark: '#515365',
    textInverse: '#fff',

    bg: '#fff',
    white: '#fff',
    secondary: '#2eb6f2',
    lightGray: '#e6ecf5',
    mediumGray: '#d6d6d6',
    subtle: '#4f899f',
    dropShadow: 'rgb(0 0 0 / 16%)',

    primary: {
      lightest: '#eff8ff',
      light: '#abd5f7',
      default: '#00809f',
      dark: '#196aa5',
      darkest: '#0f4873'
    },
    success: {
      lightest: '#e2fcec',
      light: '#a8efc2',
      default: '#73db9e',
      dark: '#179f54',
      darkest: '#08783d'
    },
    warning: {
      lightest: '#fffcf4',
      light: '#fbe39d',
      default: '#f5cb5b',
      dark: '#8e6d09',
      darkest: '#5b4500'
    },

    error: {
      lightest: '#fce7e7',
      light: '#f5a9aa',
      default: '#e5615f',
      dark: '#bb0e0b',
      darkest: '#8a0d0c'
    }
  },
  button: {
    sizes: {
      small: {
        fontSize: '14px',
        height: '30px',
        padding: '0 8px'
      },
      medium: {
        fontSize: '16px',
        height: '40px',
        padding: '0 12px'
      },
      large: {
        fontSize: '18px',
        height: '50px',
        padding: '0 16px'
      }
    }
  },
  fontSize: {
    xs: '11px',
    small: '14px',
    medium: '16px',
    large: '24px',
    xlarge: '40px'
  },
  font: {
    text: "'Montserrat', sans-serif",
    header: 'Yle',
    paragraph: "'Open sans', sans-serif"
  },
  layout: {
    headerHeight: 48,
    leftMenuWidth: 260
  },
  breaks: {
    tablet: 768,
    mobile: 620
  }
}

export function raise(level: number): string {
  switch (level) {
    case 1:
      return 'box-shadow: 1px 1px 3px rgba(0,0,0,0.1);'
    case 2:
      return 'box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);'
    case 3:
      return 'box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);'
    case 4:
      return 'box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);'
    case 5:
      return 'box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);'
    default:
      return ''
  }
}

export function truncate(width: string) {
  return css`
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
}
