import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {WelcomeBox} from '../elements/welcome.box'
import {MainContainer, MainWrapper} from '../elements/Layout'

export const NoAuthPage: React.FC = () => {
  const {t} = useTranslation()
  return (
    <MainWrapper>
      <MainContainer>
        <WelcomeBox>
          <h1>{t('noAuth.title')}</h1>
          <p dangerouslySetInnerHTML={{__html: t('noAuth.getCredentialsParty')}} />
          <p dangerouslySetInnerHTML={{__html: t('noAuth.getCredentialsOther')}} />
        </WelcomeBox>
      </MainContainer>
    </MainWrapper>
  )
}
