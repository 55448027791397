import React, {FC, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import logo from '../../images/yle_logo_musta.svg'
import {ElectionLogo} from '../components/ElectionLogo'
import {useNavigate} from 'react-router-dom'
import questionsFile from '../../documents/Eurovaalien_2024_vaalikoneen_vaitteet.pdf'
import {ContentContainer} from '../elements/Layout'
import {useStores} from '../utils/hooks'
import {Stores} from '../stores'
import {
  Button,
  Dropdown,
  FormCheckBox,
  breakpoints,
  colors,
  spacing,
  subheaderLight,
  textH1,
  textNormal
} from '@webscale-oy/vaalikone-common-ui-base'

interface IProps {
  className?: string
}

interface ILanguageOption {
  key: string
  title: string
}

export const WelcomePage: FC<IProps> = ({className}) => {
  const {candidateStore} = useStores() as Stores
  const candidate = candidateStore.candidate
  const [eulaAccepted, setEulaAccepted] = useState(!!candidate?.terms_agreed)
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()

  const languages: ILanguageOption[] = [
    {key: 'fi', title: t('common.inFinnish')},
    {key: 'sv', title: t('common.inSwedish')}
  ]

  const proceedToNextPage = async () => {
    await candidateStore.updateTermsAgreed(eulaAccepted)
    navigate(`/${candidate?.id}/info`)
  }

  return (
    <ContentContainer className={className}>
      <ElectionLogo alt={t('welcomePage.logoAltText')} src={logo} />
      <Header>{t('welcomePage.mainHeader')}</Header>
      <InfoText dangerouslySetInnerHTML={{__html: t('welcomePage.mainDescription')}} />
      <Dropdown
        label={t('welcomePage.languageSelect')}
        onSelect={l => i18n.changeLanguage(l as string)}
        options={languages}
        selected={i18n.language}
      />
      <PrivacyWrapper>
        <EulaCheckbox inputId="eula-accept-checkbox" onChange={() => setEulaAccepted(!eulaAccepted)} value={eulaAccepted} />
        <TextWithLink htmlFor="eula-accept-checkbox" dangerouslySetInnerHTML={{__html: t('welcomePage.privacyAcceptText')}} />
      </PrivacyWrapper>
      <ContinueButton disabled={!eulaAccepted} onClick={() => proceedToNextPage()}>
        {t('welcomePage.continueText')}
      </ContinueButton>
      <PrivacyWrapper>
        <CenteredTextWithLink>
          {t('welcomePage.downloadQuestionsBeginning')}
          <a tabIndex={0} href={questionsFile} download>
            {t('welcomePage.downloadQuestionsLink')}
          </a>
          {t('welcomePage.downloadQuestionsEnd')}
        </CenteredTextWithLink>
      </PrivacyWrapper>
    </ContentContainer>
  )
}

const Header = styled.h1`
  ${textH1};
  @media only screen and (max-width: ${breakpoints.mobileS}) {
    font-size: 32px;
  }
`

const InfoText = styled.p`
  ${subheaderLight};
  margin-bottom: ${spacing.space_32}px;
  @media only screen and (max-width: ${breakpoints.mobileS}) {
    padding: 0 ${spacing.space_12}px;
  }
`

const EulaCheckbox = styled(FormCheckBox)`
  margin-top: 20px;
  align-items: center;
  max-width: 60px;
`

const TextWithLink = styled.label`
  ${textNormal};
  text-align: left;
  > a {
    color: ${colors.blue_500};
    :visited {
      color: ${colors.blue_500};
    }
  }
`
const CenteredTextWithLink = styled(TextWithLink)`
  text-align: center;
`

const PrivacyWrapper = styled.div`
  display: flex;
  padding: ${spacing.space_12}px;
  justify-content: center;
  align-items: center;
  margin: 0 ${spacing.space_8}px;
`

const ContinueButton = styled(Button)`
  margin: ${spacing.space_16}px 0 ${spacing.space_24}px 0;
  min-width: 100px;
  justify-content: center;
`
