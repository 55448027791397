import type React from 'react'
import {FC} from 'react'
import styled from 'styled-components'

import {TextareaBaseProps, TextareaAtom} from './TextareaBase'
import {CaptionLight, ComponentSize, captionBold, highlight, spacing, subheaderLight} from '@webscale-oy/vaalikone-common-ui-base'

export type TextareaProps = {
  className?: string
  size?: ComponentSize
  height?: string
  maxChars?: number
} & TextareaBaseProps

const TextareaBase: FC<TextareaProps> = ({className, size, maxChars, ...props}) => (
  <TextareaWrapper className={className}>
    <TextareaRow disabled={props.disabled}>
      <TextareaText size={size} {...props} />
    </TextareaRow>
    {maxChars && (
      <MaxCharsInfo over={(props.value?.length ?? 0) > maxChars}>
        {props.value?.length ?? 0}/{maxChars}
      </MaxCharsInfo>
    )}
  </TextareaWrapper>
)

const TextareaWrapper = styled.div<{height?: string}>`
  display: flex;
  flex-direction: column;

  > label {
    ${captionBold};
    color: ${({theme}) => theme.text};
    margin-bottom: ${spacing.space_8}px;
  }
  ${CaptionLight} {
  }
`

const MaxCharsInfo = styled(CaptionLight)<{over: boolean}>`
  color: ${({theme, over}) => (over ? theme.errorPrimary : theme.textSecondary)};
  align-self: flex-end;
`

const TextareaText = styled(TextareaAtom)<{size?: ComponentSize}>`
  ${subheaderLight};
  display: flex;
  border-radius: ${({theme}) => theme.radius};
  box-shadow: none;
  border: ${({theme}) => `1px solid ${theme.borderMain}`};
  transition: all 0.2s ease-in;
  background-color: ${({theme}) => theme.background};
  color: ${({theme}) => theme.text};
  width: 100%;
  padding: ${({size}) => {
    switch (size) {
      case 'lg':
        return `${spacing.space_8}px ${spacing.space_16}px;`
      case 'xl':
        return `${spacing.space_8}px ${spacing.space_24}px;`
      default:
        return `${spacing.space_8}px ${spacing.space_12}px;`
    }
  }};
  resize: none;

  &:focus {
    outline: 0;
    border: ${({theme}) => `1px solid ${theme.primary}`};
    ${highlight('primary')};
  }

  &:disabled {
    background-color: ${({theme}) => theme.backgroundDisabled};
    color: ${({theme}) => theme.textSecondary};
    cursor: not-allowed;
  }

  &:hover {
    border: ${({theme}) => `1px solid ${theme.primary}`};
  }
`

const TextareaRow = styled.div<{disabled?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
`

export const Textarea = styled(TextareaBase)`
  position: relative;
  textarea {
    height: ${({height}) => height};
    min-height: 120px;
  }
`
