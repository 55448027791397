import * as React from 'react'
import {Stores} from './stores'
import {VaalikoneCandidateApp} from './app'
import '../images/favicon.ico'
import {createRoot} from 'react-dom/client'
import './utils/awsRum.util'

export const stores = new Stores()
const container = document.getElementById('root')
const root = createRoot(container!)

// eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
root.render(<VaalikoneCandidateApp stores={stores} />), document.getElementById('root')
