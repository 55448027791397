import * as React from 'react'
import {observer} from 'mobx-react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {Stores} from '../stores'
import {IQuestion, IMediapartnerQuestion} from '../types/question'
import {useStores} from '../utils/hooks'
import {AnswersWrapper} from '../templates/AnswersWrapper.template'
import {I18nHelper} from '../i18n/i18nHelper'
import {IBackgroundQuestion} from '../types/backgroundquestion'
import {RiVideoChatLine, RiCompass3Line, RiQuestionAnswerLine} from 'react-icons/ri'
import {AreenaVideo} from '../elements/video/AreenaVideo'
import {Notification} from '../components/Notifications'
import {NextBackNav} from '@webscale-oy/vaalikone-common-ui-candidate'
import {SummaryCard, breakpoints, spacing, textBold, textNormal} from '@webscale-oy/vaalikone-common-ui-base'

const IMG_PREFIX = import.meta.env.VITE_IMG_URL

export const SummaryPageClass: React.FC = observer(() => {
  const {
    answerStore,
    mediapartnerStore,
    backgroundInfoStore,
    questionStore,
    backgroundAnswerStore,
    candidateStore,
    dialogStore,
    municipalityStore
  } = useStores() as Stores
  const navigate = useNavigate()
  const {candidateId} = useParams<{candidateId: string}>()
  const {t, i18n} = useTranslation()
  const readOnly = candidateStore.candidate?.status === 'COMPLETED'

  const oneToFiveTranslation = (value?: number) => {
    switch (value) {
      case 1:
        return t('answerOneToFive.label1')
      case 2:
        return t('answerOneToFive.label2')
      case 3:
        return t('answerOneToFive.label3')
      case 4:
        return t('answerOneToFive.label4')
      case 5:
        return t('answerOneToFive.label5')
      case null:
        return t('summaryPage.skipped')
      default:
        return undefined
    }
  }

  const questionAnswer = (question: IQuestion) => {
    const answer = answers.find(a => a.question_id === question.id)
    if (question.type === 'PRIORITY_LIST') {
      if (answer?.options_answer === null) {
        return <ThemeParagraph key={`question_${question.id}_answer_skipped`}>{t('summaryPage.skipped')}</ThemeParagraph>
      }
      if (answer?.options_answer?.length === question.options?.length) {
        return answer?.options_answer?.map(a => (
          <ThemeParagraph key={`question_${question.id}_answer_${a}`}>
            {I18nHelper.getTranslatedPriorityListOption(question.options!.find(q => a === q.id)!, i18n)}
          </ThemeParagraph>
        ))
      }
      return undefined
    }
    if (question.type === 'YES_NO') {
      if (answer?.answer === null) {
        return t('summaryPage.skipped')
      }
      if (answer?.answer === 1) {
        return t('commonUI.generic.no')
      }
      if (answer?.answer === 5) {
        return t('commonUI.generic.yes')
      }
    }
    return oneToFiveTranslation(answer?.answer || undefined)
  }

  const mediaquestionAnswer = (question?: IMediapartnerQuestion) => {
    if (!question) {
      return undefined
    }
    const answer = mediapartnerQuestionAnswers.find(a => a.mediapartner_question_id === question.id)
    if (question.type === 'YES_NO') {
      yesNoQuestionAnswer(answer?.answer)
    }
    return oneToFiveTranslation(answer?.answer || undefined)
  }

  const yesNoQuestionAnswer = (answer?: number | null) => {
    if (answer === null) {
      return t('summaryPage.skipped')
    }
    if (answer === 1) {
      return t('commonUI.generic.no')
    }
    if (answer === 5) {
      return t('commonUI.generic.yes')
    }
  }

  const bgQuestionAnswer = (question: IBackgroundQuestion) => {
    const answer = bgQuestionAnswers.find(a => a.info_id === question.id)
    if (question.type === 'NUMERIC') return answer?.numeric_answer
    if (question.type === 'LINK') return answer?.link_answer
    if (question.type === 'SELECT') {
      if (question.multi && (!answer?.answer_options || answer?.answer_options?.length === 0)) {
        return undefined
      }
      if (!question.multi && !answer?.numeric_answer) {
        return undefined
      }
      if (question.multi) {
        return answer?.answer_options?.map(a => (
          <ThemeParagraph key={`bgquestion_${question}_answer_${a}`}>
            {I18nHelper.getTranslatedBackgroundQuestionOption(question.options!.find(q => a === q.id)!, i18n)}
          </ThemeParagraph>
        ))
      } else {
        return (
          <ThemeParagraph key={`bgquestion_${question}_answer_${answer?.numeric_answer}`}>
            {I18nHelper.getTranslatedBackgroundQuestionOption(question.options!.find(q => answer?.numeric_answer === q.id)!, i18n)}
          </ThemeParagraph>
        )
      }
    }
    if (question.type === 'YES_NO') {
      if (!answer?.numeric_answer) return undefined
      return <ThemeParagraph key={`bgquestion_${question}_answer_${answer.numeric_answer}`}>{yesNoQuestionAnswer(answer.numeric_answer)}</ThemeParagraph>
    }
    if (question.type === 'MUNICIPALITY') {
      if (!answer?.numeric_answer) return undefined
      return (
        <ThemeParagraph key={`bgquestion_${question}_answer_${answer.numeric_answer}`}>
          {I18nHelper.getMunicipalityTitle(
            municipalityStore.municipalities.find(m => m.id === answer.numeric_answer),
            i18n.language
          )}
        </ThemeParagraph>
      )
    }
    if (question.type === 'SLIDER') {
      if (!answer?.numeric_answer) return undefined
      return (
        <ThemeParagraph key={`bgquestion_${question}_answer_${answer?.numeric_answer}`}>
          {I18nHelper.getTranslatedBackgroundQuestionOption(question.options!.find(q => answer?.numeric_answer === q.target_value)!, i18n)}
        </ThemeParagraph>
      )
    }
    return answer?.[I18nHelper.getAnswerLangKey(i18n)]
  }

  const bgAnswerIndicator = (bgq: IBackgroundQuestion) => {
    if (bgq.important) {
      return bgQuestionAnswer(bgq) ? (
        <TextWrapper>{bgQuestionAnswer(bgq)}</TextWrapper>
      ) : (
        <TextWrapper>
          <NotAnswered>{t('summaryPage.notAnswered')}</NotAnswered>
        </TextWrapper>
      )
    }
    return bgQuestionAnswer(bgq) ? (
      <TextWrapper>{bgQuestionAnswer(bgq)}</TextWrapper>
    ) : (
      <TextWrapper>
        <NotAnsweredNotRequired>{t('summaryPage.notAnswered')}</NotAnsweredNotRequired>
      </TextWrapper>
    )
  }

  const {categories, questions} = questionStore
  const {candidate} = candidateStore
  const {mediapartnerQuestions, mediapartnerQuestionAnswers} = mediapartnerStore
  const {questions: bgQuestions} = backgroundInfoStore
  const {answers: bgQuestionAnswers} = backgroundAnswerStore
  const {answers} = answerStore
  const mediapartnerQuestionsWithValidQuestion = mediapartnerQuestions.filter(q => !!q.question)

  const allQuestionsAnswered = () => questions.every(q => !!questionAnswer(q))
  const requiredBgQuestions = bgQuestions.filter(bgQ => bgQ.important)
  const allBgQuestionsAnswered = () => requiredBgQuestions.every(q => !!bgQuestionAnswer(q))
  const allMediaPartnerQuestionsAnswered = () => mediapartnerQuestionsWithValidQuestion.every(q => !!mediaquestionAnswer(q.question))

  const answeredQuestions = questions.filter(q => !!questionAnswer(q))
  const answeredBgQuestions = requiredBgQuestions?.filter(q => !!bgQuestionAnswer(q))
  const targetCategory = (id: number) => categories.find(c => c.id === id)

  const isProfileReadyToPublish = () => {
    return candidate?.image && allBgQuestionsAnswered() && allQuestionsAnswered()
  }

  const publishProfile = async () => {
    if (await dialogStore.openDialog(t('summaryPage.publishConfirmTitle'), t('summaryPage.publishConfirmBody'), 'warning')) {
      await answerStore.finalizeAnswering()
      window.scrollTo(0, 0)
    }
  }

  function showCandidateStatusDialog() {
    switch (true) {
      case readOnly:
        return (
          <StatusNote
            className="successNotification"
            notification={{
              id: 0,
              type: 'success',
              hideClose: true,
              message: (
                <>
                  <StatusNoteBody>
                    <StatusNoteTitle>{t('summaryPage.profilePublishedTitle')}</StatusNoteTitle>
                    {t('summaryPage.profilePublished')}
                  </StatusNoteBody>
                </>
              )
            }}
            donePercentage={0}
          />
        )
      case isProfileReadyToPublish():
        return (
          <StatusNote
            className="successNotification"
            notification={{
              id: 0,
              type: 'success',
              hideClose: true,
              message: (
                <>
                  <StatusNoteBody>
                    <StatusNoteTitle>{t('summaryPage.profileReadyForPublishTitle')}</StatusNoteTitle>
                    {t('summaryPage.profileReadyForPublish')}
                  </StatusNoteBody>
                </>
              )
            }}
            donePercentage={0}
          />
        )
      default:
        return (
          <StatusNote
            className="errorNotification"
            notification={{
              id: 0,
              type: 'error',
              hideClose: true,
              message: (
                <>
                  <StatusNoteBody>
                    <StatusNoteTitle>{t('summaryPage.profileNotReadyForPublishTitle')}</StatusNoteTitle>
                    {t('summaryPage.profileNotReadyForPublish')}
                  </StatusNoteBody>
                </>
              )
            }}
            donePercentage={0}
          />
        )
    }
  }

  return (
    <AnswersWrapper header={t('summaryPage.mainHeader')}>
      {showCandidateStatusDialog()}
      <SummaryCardWide
        title={t('summaryPage.photo')}
        description={candidate?.image ? t('summaryPage.photo') : t('summaryPage.missing')}
        onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/kuva`)}
        icon={candidate?.image && <CandidateImage src={`${IMG_PREFIX}/${candidate.image}_thumb`} alt="ehdokaskuva" />}
        status={candidate?.image ? 'success' : 'error'}
        rows={[]}
        translations={{
          edit: t('summaryPage.edit'),
          more: t('summaryPage.more')
        }}
      />
      <SummaryCardWide
        title={t('summaryPage.video')}
        description={!!candidate?.video ? t('summaryPage.video') : t('summaryPage.videoMissing')}
        onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/video`)}
        icon={<RiVideoChatLine />}
        status={candidate?.video ? 'success' : 'error'}
        rows={
          candidate?.video
            ? [
                {
                  title: '',
                  value: (
                    <Video>
                      <AreenaVideo videoId={candidate?.video} />
                    </Video>
                  )
                }
              ]
            : []
        }
        translations={{
          edit: t('summaryPage.edit'),
          more: t('summaryPage.more')
        }}
      />
      <SummaryCardWide
        title={t('summaryPage.bgQuestions')}
        description={
          allBgQuestionsAnswered()
            ? `${answeredBgQuestions.length}/${requiredBgQuestions.length} ${t('summaryPage.bgQuestionsCompleted')}`
            : `${answeredBgQuestions.length}/${requiredBgQuestions.length} ${t('summaryPage.bgQuestionsAmountAnswered')}`
        }
        onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/taustakysymykset`)}
        icon={<RiQuestionAnswerLine size={20} />}
        status={allBgQuestionsAnswered() ? 'success' : 'error'}
        maxShown={10}
        rows={bgQuestions.map(bgq => ({
          title: <QuestionTitle>{I18nHelper.getBackgroundQuestionTitle(bgq, i18n.language)}</QuestionTitle>,
          value: bgAnswerIndicator(bgq),
          noRowWrap: true
        }))}
        translations={{
          edit: t('summaryPage.edit'),
          more: t('summaryPage.more')
        }}
      />
      <SummaryCardWide
        title={t('summaryPage.questions')}
        description={
          allQuestionsAnswered()
            ? `${answeredQuestions.length}/${questions.length} ${t('summaryPage.completed')}`
            : `${answeredQuestions.length}/${questions.length} ${t('summaryPage.amountAnswered')}`
        }
        onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/kysymykset`)}
        icon={<RiCompass3Line size={20} />}
        status={allQuestionsAnswered() ? 'success' : 'error'}
        rows={questions.map((q, index) => ({
          title: <QuestionTitle>{I18nHelper.getTranslatedQuestion(q, i18n)}</QuestionTitle>,
          value: questionAnswer(q) ? (
            <TextWrapper>{questionAnswer(q)}</TextWrapper>
          ) : (
            <TextWrapper>
              <NotAnswered>{t('summaryPage.notAnswered')}</NotAnswered>
            </TextWrapper>
          ),
          noRowWrap: true,
          sectionHeader:
            index === 0 ? <SectionHeader>{I18nHelper.getTranslatedCategory(targetCategory(q.category_id), i18n)}</SectionHeader> : undefined
        }))}
        maxShown={10}
        translations={{
          edit: t('summaryPage.edit'),
          more: t('summaryPage.more')
        }}
      />
      <SummaryCardWide
        title={t('summaryPage.mediaquestions')}
        description={
          allMediaPartnerQuestionsAnswered()
            ? `${mediapartnerQuestionAnswers.length}/${mediapartnerQuestionsWithValidQuestion.length} ${t('summaryPage.completed')}`
            : `${mediapartnerQuestionAnswers.filter(a => !!a.answer).length}/${mediapartnerQuestionsWithValidQuestion.length} 
            ${t('summaryPage.amountAnswered')}`
        }
        onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/mediakumppanit`)}
        icon={<RiCompass3Line size={20} />}
        status={allMediaPartnerQuestionsAnswered() ? 'success' : 'error'}
        rows={mediapartnerQuestionsWithValidQuestion.map((q, index) => ({
          title: <QuestionTitle>{I18nHelper.getTranslatedQuestion(q.question, i18n)}</QuestionTitle>,
          value: mediaquestionAnswer(q.question) ? (
            <TextWrapper>{mediaquestionAnswer(q.question)}</TextWrapper>
          ) : (
            <TextWrapper>
              <NotAnsweredNotRequired>{t('summaryPage.notAnswered')}</NotAnsweredNotRequired>
            </TextWrapper>
          ),
          noRowWrap: true,
          sectionHeader: <SectionHeader>{I18nHelper.getMediaPartnerName(q.mediapartner, i18n)}</SectionHeader>
        }))}
        maxShown={10}
        translations={{
          edit: t('summaryPage.edit'),
          more: t('summaryPage.more')
        }}
      />
      <NextBackNav
        backTo={() => navigate(`/${candidateId}/mediakumppanit`)}
        nextTo={() => publishProfile()}
        onSummaryPage
        continueDisabled={!isProfileReadyToPublish() || readOnly}
        translations={{
          next: t('nextBackNav.continue'),
          back: t('nextBackNav.back'),
          finish: t('nextBackNav.publishProfile')
        }}
      />
    </AnswersWrapper>
  )
})

export const SummaryPage = SummaryPageClass

const Video = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const CandidateImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 100%;
`

const TextWrapper = styled.div`
  ${textNormal};
  width: 40%;
  margin-left: ${spacing.space_72}px;
  text-align: left;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    margin-left: 0;
  }
`

const QuestionTitle = styled.p`
  ${textNormal};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SectionHeader = styled.p`
  ${textBold};
  display: flex;
  text-align: left;
  position: absolute;
  padding-bottom: 64px;
  font-weight: 600;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    position: relative;
    padding-bottom: 0;
  }
`

const SummaryCardWide = styled(SummaryCard)`
  width: 100%;
  :not(:last-child) {
    margin-bottom: ${spacing.space_24}px;
  }
`
const ThemeParagraph = styled.p`
  ${textNormal};
  text-align: left;
`
const NotAnswered = styled(ThemeParagraph)`
  color: ${({theme}) => theme.errorPrimary};
  font-weight: bold;
`
const NotAnsweredNotRequired = styled(ThemeParagraph)`
  color: ${({theme}) => theme.warningPrimary};
`
const StatusNote = styled(Notification)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-bottom: ${spacing.space_16}px;
  padding: 14px 60px;

  img {
    left: 19px;
  }

  @media only screen and (max-width: ${breakpoints.mobileS}) {
    padding: 14px 44px;

    img {
      left: 10px;
    }
  }
`

const StatusNoteBody = styled.div`
  text-align: left;
`
const StatusNoteTitle = styled.div`
  font-weight: bold;
  margin-bottom: ${spacing.space_8}px;
`
