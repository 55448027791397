import {ProgressBar, captionNormal, spacing} from '@webscale-oy/vaalikone-common-ui-base'
import React, {FC} from 'react'
import styled from 'styled-components'

type Props = {
  infoTextBeginning: string
  infoTextEnd: string
  amountOfAnswers: number
  amountOfQuestions: number
}

export const PageBottomProgress: FC<Props> = ({infoTextBeginning, infoTextEnd, amountOfAnswers, amountOfQuestions}) => (
  <BottomProgress>
    <ProgressBar progress={(amountOfAnswers / amountOfQuestions) * 100} />
    <InfoP>
      {infoTextBeginning} {amountOfAnswers} / {amountOfQuestions} {infoTextEnd}
    </InfoP>
  </BottomProgress>
)

const InfoP = styled.p`
  ${captionNormal};
  padding-bottom: ${spacing.space_8}px;
`

const BottomProgress = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 294px;
  flex-direction: column;
`
