import React, {FC, ReactNode} from 'react'
import styled from 'styled-components'
import {raise, theme} from '../theme'

interface IProps {
  className?: string
  title?: React.ReactNode
  action?: React.ReactNode
  children?: ReactNode
}

const CardComponent: FC<IProps> = ({className, title, action, children}) => (
  <div className={className}>
    {title && (
      <TitleWrapper>
        <Title tabIndex={0}>{title}</Title>
      </TitleWrapper>
    )}
    {action && <Action>{action}</Action>}
    {children}
  </div>
)

const Title = styled.h3`
  font-weight: 600;
  margin: 0;
  color: ${theme.color.primary.darkest};
`

const TitleWrapper = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
`

const Action = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`
export const Card = styled(CardComponent)`
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  min-height: 200px;
  width: 100%;
  ${raise(1)}
`
