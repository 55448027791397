import styled from 'styled-components'
import {checkColorVisibility} from '../../utils/helper.util'
import {
  AnswerOneToFive,
  FormRow,
  breakpoints,
  captionNormal,
  colors,
  spacing,
  subheaderLight,
  textH1,
  textH3,
  textH4
} from '@webscale-oy/vaalikone-common-ui-base'
import {FormMultiLangTextarea} from '../../components/FormElements/TextArea/MultiLangTextarea'

export const CategoryHeader = styled.h3<{bgColor?: string}>`
  ${textH3};
  color: ${({theme, bgColor}) => checkColorVisibility(theme, bgColor)};
  margin: 40px 0 20px 0;
  background-color: ${({bgColor}) => bgColor};
  border-radius: ${({theme}) => theme.radiusLarge};
  margin-top: 0;
`

export const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 ${spacing.space_48}px 0;
  flex-direction: column;
  ${AnswerOneToFive} {
    p {
      ${subheaderLight};
      font-size: 12px;
      color: ${colors.grey_800};
    }
  }
`

export const QuestionCountSection = styled.div`
  > h1 {
    display: inline;
    ${textH1};
  }
  > h4 {
    display: inline;
    color: ${({theme}) => theme.textSecondary};
    ${textH4};
  }
  margin: 10px 0;
`

export const QuestionHeader = styled.h3`
  ${textH3}
`
export const ComponentWrapper = styled(FormRow)`
  display: flex;
  justify-content: space-between;
  width: 760px;
  flex-direction: column;

  ${FormMultiLangTextarea} {
    > div {
      > label {
        text-align: left;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`

export const InfoP = styled.p`
  ${captionNormal};
  padding-top: ${spacing.space_12}px;
  padding-bottom: ${spacing.space_8}px;
`
