import type React from 'react'
import {useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {ScrollShadow, ScrollShadowProps} from '../ScrollShadow'
import {Textarea, TextareaProps} from './Textarea'
import {captionNormal, spacing, withFormField} from '@webscale-oy/vaalikone-common-ui-base'

type Languages = {[langCode: string]: string}
type KeyOf<T extends object> = Extract<keyof T, string>

export type MultiLangTextareaProps = {
  /**
   * language that should be active from the beginning
   */
  activeLang: KeyOf<Languages>
  /**
   * supported languages (lang code + translation)
   */
  languages: Languages
  value?: {[key: KeyOf<Languages>]: string | undefined}
  onChange: (language: KeyOf<Languages>, value: string) => void
  onBlur?: (language: KeyOf<Languages>, value?: string) => void
  scrollShadow?: ScrollShadowProps
  /**
   * shows a required symbol next to the main language
   */
  mainLanguage?: string
} & Omit<TextareaProps, 'onChange' | 'value' | 'onBlur'>

/**
 *  TODO H-P 2022-04-11: Add support for more languages than fits the screen
 */
const MultiLangTextAreaElement = ({
  languages,
  activeLang,
  className,
  onChange,
  value = {},
  onBlur,
  scrollShadow,
  mainLanguage,
  ...props
}: MultiLangTextareaProps) => {
  const {t} = useTranslation()
  const [active, setActive] = useState<KeyOf<Languages>>(activeLang)
  const isMultiLang = useMemo(() => Object.keys(languages).length > 1, [languages])
  return (
    <div className={className}>
      {isMultiLang && (
        <Tabs>
          <ScrollShadow {...scrollShadow}>
            {Object.keys(languages).map(langCode => (
              <Tab
                key={langCode}
                className={active === langCode ? 'active' : ''}
                onClick={() => setActive(langCode)}
                active={active === langCode}
              >
                {languages[langCode]}
                {mainLanguage === langCode && <span aria-label={t('commonUI.generic.required')}>&#42;</span>}
              </Tab>
            ))}
          </ScrollShadow>
        </Tabs>
      )}
      <Textarea
        {...props}
        value={value[active] ?? ''}
        onChange={val => onChange(active, val)}
        onBlur={val => onBlur && onBlur(active, val)}
        dir={active === 'ara' ? 'rtl' : 'ltr'}
      />
    </div>
  )
}

const Tabs = styled.div`
  display: flex;
  align-items: center;
  overflow-x: hidden;
`
const Tab = styled.div<{active: boolean}>`
  ${captionNormal}
  color: ${({theme, active}) => (active ? theme.text : theme.textSecondary)};
  padding: ${spacing.space_8}px ${spacing.space_16}px;
  border: 1px solid transparent;
  border-radius: ${({theme}) => `${theme.radius} ${theme.radius} 0 0`};
  border-color: ${({theme, active}) => (active ? theme.borderMain : 'transparent')};
  border-bottom-color: ${({theme, active}) => (active ? theme.background : 'transparent')} !important;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease-in;
`

export const MultiLangTextarea = styled(MultiLangTextAreaElement)`
  flex: 1;
  ${Textarea} {
    textarea {
      border-color: ${({theme}) => theme.borderMain};
      border-radius: ${({theme}) => ` 0 0 ${theme.radius} ${theme.radius}`};
      border-top-color: ${({theme}) => theme.borderMain};
      margin-top: -1px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  &:focus-within {
    ${Textarea} {
      textarea {
        border-color: ${({theme}) => theme.borderPrimary};
        border-top-color: ${({theme}) => theme.borderPrimary};
      }
    }
    ${Tab} {
      &.active {
        border-color: ${({theme}) => theme.borderPrimary};
      }
    }
  }
`

export const FormMultiLangTextarea = styled(withFormField(MultiLangTextarea))``
