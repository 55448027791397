import * as i18next from 'i18next'
import {IQuestion, IMediapartnerQuestion, IQuestionOption} from '../types/question'
import {IQuestionCategory} from '../types/question-category'
import {IMediapartner} from '../types/mediapartner'
import {IBackgroundInfoOption, IBackgroundQuestion} from '../types/backgroundquestion'
import {IMunicipality} from '../types/municipality'

const sanitize = (translatedQuestion: string) => {
  const additionalInfoRemoved = `${translatedQuestion.split('{{')[0]}${translatedQuestion.split('}}')[1] || ''}`
  const additionalInfoAndPlusMinusRemoved = `${additionalInfoRemoved.split('[[')[0]}${additionalInfoRemoved.split(']]')[1] || ''}`
  return additionalInfoAndPlusMinusRemoved
}

export const I18nHelper = {
  getTranslatedQuestion(question: IQuestion | IMediapartnerQuestion | undefined, i18n: i18next.i18n) {
    if (!question) {
      return ''
    }
    let translation
    switch (i18n.language) {
      case 'sv':
        translation = question.text_sv
        break
      case 'en':
        translation = question.text_en
        break
      case 'ru':
        translation = question.text_ru
        break
      case 'sme':
        translation = question.text_sme
        break
      case 'ara':
        translation = question.text_ara
        break
      default:
        translation = question.text_fi
    }
    return sanitize(translation || question.text_fi)
  },

  getTranslatedCategory(category: IQuestionCategory | undefined, i18n: i18next.i18n) {
    if (!category) {
      return ''
    }
    let translation
    switch (i18n.language) {
      case 'sv':
        translation = category.name_sv
        break
      case 'en':
        translation = category.name_en
        break
      case 'ru':
        translation = category.name_ru
        break
      case 'sme':
        translation = category.name_sme
        break
      case 'ara':
        translation = category.name_ara
        break
      default:
        translation = category.name_fi
    }
    return translation || category.name_fi
  },

  getTranslatedPriorityListOption(option: IQuestionOption, i18n: i18next.i18n) {
    let translation
    switch (i18n.language) {
      case 'sv':
        translation = option.text_sv
        break
      case 'en':
        translation = option.text_en
        break
      case 'ru':
        translation = option.text_ru
        break
      case 'sme':
        translation = option.text_sme
        break
      case 'ara':
        translation = option.text_ara
        break
      default:
        translation = option.text_fi
    }
    return translation || option.text_fi
  },
  getTranslatedBackgroundQuestionOption(option: IBackgroundInfoOption, i18n: i18next.i18n) {
    if (!option) {
      return ''
    }
    let translation
    switch (i18n.language) {
      case 'sv':
        translation = option.name_sv
        break
      case 'en':
        translation = option.name_en
        break
      case 'ru':
        translation = option.name_ru
        break
      case 'sme':
        translation = option.name_sme
        break
      case 'ara':
        translation = option.name_ara
        break
      default:
        translation = option.name_fi
    }
    return translation || option.name_fi
  },
  getMediaPartnerName(mediapartner: IMediapartner | undefined, i18n: i18next.i18n) {
    if (!mediapartner) {
      return ''
    }
    let translation
    switch (i18n.language) {
      case 'sv':
        translation = mediapartner.name_sv
        break
      case 'en':
        translation = mediapartner.name_en
        break
      case 'ru':
        translation = mediapartner.name_ru
        break
      case 'sme':
        translation = mediapartner.name_sme
        break
      case 'ara':
        translation = mediapartner.name_ara
        break
      default:
        translation = mediapartner.name_fi
    }
    return translation || mediapartner.name_fi
  },
  getAnswerLangKey(i18n: i18next.i18n) {
    const lang = i18n.language
    switch (lang) {
      case 'sv':
        return 'answer_text_sv'
      case 'en':
        return 'answer_text_en'
      case 'ru':
        return 'answer_text_ru'
      case 'sme':
        return 'answer_text_sme'
      case 'ara':
        return 'answer_text_ara'
      default:
        return 'answer_text_fi'
    }
  },
  getExplanationLangKey(lang: string) {
    switch (lang) {
      case 'sv':
        return 'explanation_sv'
      case 'en':
        return 'explanation_en'
      case 'ru':
        return 'explanation_ru'
      case 'sme':
        return 'explanation_sme'
      case 'ara':
        return 'explanation_ara'
      default:
        return 'explanation_fi'
    }
  },
  getBackgroundQuestionTitle(bgQuestion: IBackgroundQuestion, lang: string) {
    switch (lang) {
      case 'sv':
        return bgQuestion.name_sv
      case 'en':
        return bgQuestion.name_en
      case 'ru':
        return bgQuestion.name_ru
      case 'sme':
        return bgQuestion.name_sme
      case 'ara':
        return bgQuestion.name_ara
      default:
        return bgQuestion.name_fi
    }
  },
  getMunicipalityTitle(municipality: IMunicipality | undefined, lang: string) {
    if (!municipality) {
      return undefined
    }
    switch (lang) {
      case 'sv':
        return municipality.name_sv
      case 'en':
        return municipality.name_en
      case 'ru':
        return municipality.name_ru
      case 'sme':
        return municipality.name_sme
      default:
        return municipality.name_fi
    }
  }
}
