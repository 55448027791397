import {action, computed, makeAutoObservable, runInAction} from 'mobx'
import {NotificationStore} from './NotificationStore'
import {authenticatedHeaders, get} from '../api/methods'
import {IBackgroundQuestion} from '../types/backgroundquestion'

export class BackgroundInfoStore {
  questions: IBackgroundQuestion[] = []
  loading = false

  notificationStore: NotificationStore

  constructor(notificationStore: NotificationStore) {
    this.notificationStore = notificationStore
    makeAutoObservable(this)
  }

  @action
  async loadQuestions(candidateId: number): Promise<void> {
    this.loading = true
    const questions = await get<IBackgroundQuestion[]>(`${candidateId}/background-info/questions`, authenticatedHeaders())
    questions.sort((q1, q2) => {
      if (!q2.position && !q2.position) {
        return 0
      }
      if (!q1.position) {
        return -1
      }
      if (!q2.position) {
        return 1
      }
      return q1.position - q2.position
    })
    runInAction(() => {
      this.questions = questions.map(q => {
        if (q.options) {
          q.options.sort((q1, q2) => q1.id - q2.id)
        }
        return q
      })
      this.loading = false
    })
  }

  @computed
  get importantQuestionAmount() {
    return this.questions.filter(q => !!q.important).length
  }
}
