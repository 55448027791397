import React, {useState, useLayoutEffect} from 'react'

type Props = {
  seconds: number
  timerDone: () => void
  render?: (secondsLeft: number) => JSX.Element
}

export const CountdownComponent = ({seconds, timerDone, render}: Props) => {
  const [timeLeft, setTimeLeft] = useState<number>(seconds)

  useLayoutEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1)
      } else {
        clearInterval(timer)
        timerDone && timerDone()
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [seconds, timeLeft, timerDone])

  if (timeLeft === 0) {
    return null
  }

  return <>{render ? render(timeLeft) : timeLeft}</>
}

export const Countdown = CountdownComponent
