import {CommonTheme, hexVisibilityDark} from '@webscale-oy/vaalikone-common-ui-base'

export const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj)) as T

export const checkColorVisibility = (theme: CommonTheme, bgColor?: string) => {
  if (bgColor) {
    return hexVisibilityDark(bgColor) ? theme.textInverse : theme.textSecondary
  }
  return theme.textSecondary
}
