import styled from 'styled-components'
import type React from 'react'
import {useEffect, useRef, useState} from 'react'

export type ScrollShadowProps = {
  orientation?: 'horizontal' | 'vertical'
  shadowColor?: string
  shadowSize?: string
  children?: React.ReactNode
}

const ScrollShadowElement: React.FC<ScrollShadowProps> = ({
  orientation = 'horizontal',
  shadowColor = 'white',
  shadowSize = '20px',
  children
}) => {
  const [showStart, setShowStart] = useState(false)
  const [showEnd, setShowEnd] = useState(false)
  const ref = useRef<any>()
  useEffect(() => {
    const onScroll = () => {
      if (orientation === 'horizontal') {
        const {scrollWidth = 0, scrollLeft = 0, offsetWidth = 0} = ref.current || {}
        setShowStart(scrollLeft > 0)
        setShowEnd(scrollLeft + offsetWidth < scrollWidth)
      } else {
        const {scrollHeight = 0, scrollTop = 0, offsetHeight = 0} = ref.current || {}
        setShowStart(scrollTop > 0)
        setShowEnd(scrollTop + offsetHeight + 5 < scrollHeight)
      }
    }
    onScroll()
    const node = ref.current
    node.addEventListener('scroll', onScroll)
    return () => {
      node.removeEventListener('scroll', onScroll)
    }
  }, [orientation])
  return (
    <Wrapper>
      {orientation === 'horizontal' && showStart && <HorizontalShadow beginShadow size={shadowSize} shadowColor={shadowColor} />}
      {orientation === 'vertical' && showStart && <VerticalShadow beginShadow size={shadowSize} shadowColor={shadowColor} />}
      <Container ref={ref} shadowOrientation={orientation}>
        {children}
      </Container>
      {orientation === 'vertical' && showEnd && <VerticalShadow beginShadow={false} size={shadowSize} shadowColor={shadowColor} />}
      {orientation === 'horizontal' && showEnd && <HorizontalShadow beginShadow={false} size={shadowSize} shadowColor={shadowColor} />}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
`
const HorizontalShadow = styled.div<{beginShadow: boolean; size: string; shadowColor: string}>`
  position: absolute;
  z-index: 100;
  height: 100%;
  width: ${({size}) => size};
  background: ${({shadowColor}) => shadowColor};
  ${props =>
    props.beginShadow
      ? `background: linear-gradient(to left, transparent 0%, ${props.shadowColor} 100%);`
      : `background: linear-gradient(to right, transparent 0%, ${props.shadowColor} 100%);`}
  ${({beginShadow}) => (beginShadow ? 'left: 0;' : 'right: 0;')}
  top: 0;
`
const VerticalShadow = styled.div<{beginShadow: boolean; size: string; shadowColor: string}>`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: ${({size}) => size};
  background: ${({shadowColor}) => shadowColor};
  ${props =>
    props.beginShadow
      ? `background: linear-gradient(to top, transparent 0%, ${props.shadowColor} 100%);`
      : `background: linear-gradient(to bottom, transparent 0%, ${props.shadowColor} 100%);`}
  ${({beginShadow}) => (beginShadow ? 'top: 0;' : 'bottom: 0;')}
  left: 0;
`
const Container = styled.div<{shadowOrientation: 'horizontal' | 'vertical'}>`
  overflow: auto;
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  ${({shadowOrientation}) => (shadowOrientation === 'horizontal' ? 'flex-direction: row;' : 'flex-direction: column;')}
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

export const ScrollShadow = styled(ScrollShadowElement)``
