import * as React from 'react'

import styled from 'styled-components'
import {color, radius, typography} from '@yleisradio/yle-design-system'
import {raise, theme} from '../theme'
import {LoaderIcon} from './LoaderIcon'
import {spacing} from '@webscale-oy/vaalikone-common-ui-base'

export type Intent = 'primary' | 'warning' | 'error' | 'success' | 'default' | 'neutral' | 'secondary' | 'yleprimary'

export type Size = 'xs' | 's' | 'm' | 'l'

export interface IButtonProps {
  className?: string
  onClick?: () => void
  disabled?: boolean
  intent?: Intent
  size?: Size
  bgImg?: string
  loading?: boolean
  color?: string
  bgColor?: string
  icon?: React.ReactNode
  tabIndex?: number
  onKeyPress?: (e: React.KeyboardEvent<HTMLButtonElement>) => void
  children?: React.ReactNode
}

class ButtonClass extends React.PureComponent<IButtonProps> {
  static defaultProps: Pick<IButtonProps, 'intent' | 'size' | 'loading' | 'tabIndex'> = {
    intent: 'default',
    size: 'm',
    loading: false,
    tabIndex: 0
  }

  public render() {
    const {onClick, className, disabled, tabIndex, onKeyPress} = this.props
    return (
      <button
        className={className}
        type={onClick ? 'button' : 'submit'}
        onClick={onClick}
        disabled={disabled}
        tabIndex={tabIndex}
        onKeyPress={onKeyPress}
      >
        {this.props.loading ? <LoaderIcon height={24} color={theme.color.textInverse} /> : this.props.children}
      </button>
    )
  }
}

const ButtonComponent = styled(ButtonClass)<IButtonProps>`
  position: relative;
  color: ${({intent, color}) => color || getColor(intent!)};
  font-size: ${props => getFontSize(props.size!)}px;
  font-family: ${theme.font.text};
  font-weight: 600;
  text-align: center;
  background: ${({intent, bgColor}) => bgColor || getBackgroundColor(intent!)};
  opacity: ${({disabled}) => (disabled ? 0.4 : 1)};
  border-radius: 2px;
  border: none;

  &:active {
    opacity: 0.8;
  }

  padding: ${({size}) => getSize(10, size!)};
  width: auto;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(${({bgImg}) => bgImg || ''}) center;
    background-size: cover;
    opacity: 1;
    z-index: -1;
  }

  transition: all 0.2s;
  &:hover {
    ${({disabled}) => (disabled ? 1 : raise(3))};
  }
`
export const Button = styled(ButtonComponent)``
export const PillButton = styled(ButtonComponent)`
  border-radius: ${radius.FULL};
  border: 2px solid ${color.GRAY_95};
  padding: 14px 24px;
  font-family: ${typography.OPEN_SANS_S_BOLD_FONT_FAMILY};
  img {
    margin-right: ${spacing.space_8}px;
  }
`
export const RoundButton = styled(ButtonComponent)`
  border-radius: 100vh;
  width: ${({size}) => getRoundSize(size!)};
  height: ${({size}) => getRoundSize(size!)};
  padding: 0;
  font-size: 18px;

  :focus {
    border: 2px solid ${color.GRAY_95};
    box-sizing: border-box;
    box-shadow: '0px 0px 0px 2px #ffffff', '0px 0px 0px 4px #121314';
    border-radius: 9999px;
  }
`
export const LinkButton = styled(ButtonComponent)`
  background: transparent;
  border: transparent;
  box-shadow: none;
  color: ${({intent}) => getBackgroundColor(intent!)};
`

function getFontSize(size: Size) {
  switch (size) {
    case 's':
      return 14
    case 'm':
      return 14
    case 'l':
      return 16
    default:
      return 14
  }
}

function getSize(base: number, size: Size) {
  switch (size) {
    case 's':
      return `${base / 4}px ${base / 2}px`
    case 'm':
      return `${base}px ${base * 2}px`
    case 'l':
      return `${base}px ${base * 4}px`
    default:
      return `${base}px ${base * 2}px`
  }
}

function getBackgroundColor(type: Intent) {
  switch (type) {
    case 'error':
      return theme.color.error.lightest
    case 'primary':
      return theme.color.primary.default
    case 'yleprimary':
      return color.GRAY_95
    case 'success':
      return theme.color.success.light
    case 'warning':
      return theme.color.warning.light
    case 'neutral':
      return '#fff'
    case 'secondary':
      return 'transparent'
    default:
      return theme.color.textMain
  }
}

function getColor(type: Intent) {
  switch (type) {
    case 'error':
      return theme.color.error.dark
    case 'success':
      return theme.color.success.darkest
    case 'warning':
      return theme.color.warning.dark
    case 'yleprimary':
      return color.GRAY_5
    case 'primary':
      return theme.color.white
    case 'neutral':
      return theme.color.textMain
    case 'secondary':
      return color.GRAY_95
    default:
      return color.WHITE
  }
}

function getRoundSize(size: Size) {
  switch (size) {
    case 's':
      return '32px'
    case 'm':
      return '40px'
    case 'l':
      return '60px'
    default:
      return '80px'
  }
}
