import React from 'react'
import styled from 'styled-components'
import {color, typography} from '@yleisradio/yle-design-system'
import {theme} from '../theme'

interface IProps {
  disabled?: boolean
  className?: string
  value?: string | number
  placeHolder?: string
  required?: boolean
  onChange: (value: any) => void // Basically one of: string | number | file but this is easier
  type: 'email' | 'password' | 'text' | 'number' | 'textarea'
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyPress?: (e: React.KeyboardEvent) => void
  icon?: React.ReactNode
  color?: string
  backgroundColor?: string
  requiredNotAnswered?: boolean
  dir?: 'ltr' | 'rtl'
  max?: number
  min?: number
}

class InputClass extends React.PureComponent<IProps> {
  public static defaultProps: Pick<IProps, 'disabled' | 'type' | 'required'> = {
    disabled: false,
    type: 'text',
    required: false
  }

  private onChange = (event: any) => {
    !this.props.disabled && this.props.onChange(event.target.value === '' ? undefined : event.target.value)
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.icon && <IconElement>{this.props.icon}</IconElement>}
        {this.props.type === 'textarea' ? (
          <TextElement
            className={this.props.className}
            value={this.props.value}
            placeholder={this.props.placeHolder}
            disabled={this.props.disabled}
            required={this.props.required}
            onChange={this.onChange}
            color={this.props.color}
            requiredNotAnswered={this.props.requiredNotAnswered}
            dir={this.props.dir || 'ltr'}
          />
        ) : (
          <InputElement
            type={this.props.type}
            className={this.props.className}
            value={this.props.value || ''}
            placeholder={this.props.placeHolder}
            disabled={this.props.disabled}
            required={this.props.required}
            onChange={this.onChange}
            onFocus={this.props.onFocus}
            onKeyPress={this.props.onKeyPress}
            color={this.props.color}
            backgroundColor={this.props.backgroundColor}
            requiredNotAnswered={this.props.requiredNotAnswered}
            dir={this.props.dir || 'ltr'}
            max={this.props.max}
            min={this.props.min || 0}
          />
        )}
        {this.props.required && <RequiredIcon>*</RequiredIcon>}
      </div>
    )
  }
}

const getBackgroundColor = (backgroundColor?: string, requiredNotAnswered?: boolean) => {
  if (requiredNotAnswered) return color.RED_5
  if (backgroundColor) return backgroundColor
  return color.WHITE
}

const getColor = (colour?: string, requiredNotAnswered?: boolean) => {
  if (requiredNotAnswered) return color.GRAY_60
  if (colour) return colour
  return theme.color.textDefault
}

const IconElement = styled.span`
  height: 100%;
  width: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: ${theme.color.lightGray};
  > * {
    height: 100%;
    width: 24px;
    fill: #fff;
  }
`

const RequiredIcon = styled.span`
  width: 12px;
  position: absolute;
  top: 4px;
  right: 4px;
  overflow: hidden;
  display: inline-block;
  z-index: 1;
  color: #ff9494;
  font-size: 1.2em;
  font-weight: bold;
`
const InputElement = styled.input<{color?: string; backgroundColor?: string; requiredNotAnswered?: boolean}>`
  display: block;
  border: 1px solid ${theme.color.lightGray};
  border-radius: 2px;
  box-shadow: none;
  font-size: 16px;
  color: ${({color}) => color || theme.color.textMain};
  transition: all 0.2s ease-in;
  padding: 10px 20px;
  background-color: ${({backgroundColor}) => backgroundColor || color.WHITE};
  width: 100%;
  background-color: ${({backgroundColor, requiredNotAnswered}) => getBackgroundColor(backgroundColor, requiredNotAnswered)};
  color: ${({color, requiredNotAnswered}) => getColor(color, requiredNotAnswered)};

  ::placeholder {
    color: ${({color, requiredNotAnswered}) => getColor(color, requiredNotAnswered)};
    opacity: 1;
    font-weight: bold;
    font-size: ${typography.OPEN_SANS_XS_BOLD_FONT_SIZE};
    font-family: ${typography.OPEN_SANS_XS_BOLD_FONT_FAMILY};
  }

  &:focus {
    color: ${theme.color.textDark};
    background-color: #fff;
    border-color: ${theme.color.secondary};
    outline: 0;
  }

  &:disabled {
    background-color: #eee;
    color: ${theme.color.textDefault};
  }
`
const TextElement = styled.textarea<{color?: string; backgroundColor?: string; requiredNotAnswered?: boolean}>`
  display: block;
  border: 1px solid ${theme.color.lightGray};
  border-radius: 2px;
  box-shadow: none;
  font-size: 16px;
  color: ${({color}) => color || theme.color.textDefault};
  transition: all 0.2s ease-in;
  padding: 10px 20px;
  background-color: ${({backgroundColor}) => backgroundColor || color.WHITE};
  width: 100%;
  background-color: ${({backgroundColor, requiredNotAnswered}) => getBackgroundColor(backgroundColor, requiredNotAnswered)};
  color: ${({color, requiredNotAnswered}) => getColor(color, requiredNotAnswered)};

  &:focus {
    color: ${theme.color.textDark};
    background-color: #fff;
    border-color: ${theme.color.secondary};
    outline: 0;
  }

  &:disabled {
    background-color: #eee;
    color: ${theme.color.textDefault};
  }

  &::placeholder {
    color: ${({color, requiredNotAnswered}) => getColor(color, requiredNotAnswered)};
    opacity: 1;
    font-weight: bold;
    font-size: ${typography.OPEN_SANS_XS_BOLD_FONT_SIZE};
    font-family: ${typography.OPEN_SANS_XS_BOLD_FONT_FAMILY};
  }
`
export const Input = styled(InputClass)`
  position: relative;
  width: 100%;
`
